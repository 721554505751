import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Button
} from '@mui/material';
import DateInput from 'src/components/UI/input/DateInput';
import { validatePhn } from 'src/util/util';

import Switch from '@mui/material/Switch';
import FormLabel from 'src/components/UI/input/CustomFormLabel';
import {
  CONSTANTS,
  diagnosesMap
} from 'src/components/questionnaire/Constants';
import SelectInput from 'src/components/UI/input/SelectInput';
import RadioInput from 'src/components/UI/input/RadioInput';
import TextInput from 'src/components/UI/input/TextInput';

function PersonMenu({
  formValues,
  handleChange,
  handleSubmit,
  dateChangeHandler,
  handleDiagnosisAgeChange,
  handleClose
}) {
  const allDiagnoses = CONSTANTS.diagnoses.concat(
    CONSTANTS.generalDiagnoses.concat(
      CONSTANTS.pregnanciesDiagnoses.concat(CONSTANTS.bloodDiagnoses)
    )
  );

  return (
    <div style={{ maxWidth: 390 }} className="user-form">
      <Grid container spacing={2}>
        {/* gender */}
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Grid container spacing={1}>
              <Grid item>
                <FormLabel>Gender </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  fullWidth={true}
                  variant="outlined"
                  label={formValues.genderIdentity}
                  name="genderIdentity"
                  options={CONSTANTS.genderIdentityOptions}
                  value={
                    formValues.genderIdentity ??
                    formValues?.gender[0].toUpperCase() +
                      formValues.gender.substring(1)
                  }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        {/* gender */}
        <Grid item xs={12}>
          <RadioInput
            label="Sex Assigned at Birth"
            name="gender"
            value={formValues.gender || ''}
            options={CONSTANTS.genderOptions}
            row
            disabled
          />
        </Grid>

        {/* name */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormLabel component="label" className="form-label">
                First Name
              </FormLabel>
              <TextField
                size="small"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel component="label" className="form-label">
                Last Name
              </FormLabel>
              <TextField
                size="small"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* date */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormLabel component="label" className="form-label">
                Date of Birth
              </FormLabel>
              <DateInput
                value={formValues.dob}
                onChange={(date) => dateChangeHandler('dob', date)}
                disableFuture
              />
            </Grid>
            <Grid item xs={6}>
              <FormLabel component="label" className="form-label">
                Date of Death
              </FormLabel>
              <DateInput
                disabled={formValues.isAlive === 'true'}
                value={formValues.dod}
                onChange={(date) => dateChangeHandler('dod', date)}
                disableFuture
              />
            </Grid>
          </Grid>
        </Grid>

        {/* isAlive */}
        <Grid item xs={12}>
          <div className="margin-top-4"></div>
          <FormControl component="fieldset">
            <FormLabel component="label" className="form-label">
              Individual is
            </FormLabel>
            <RadioGroup
              aria-label="isAlive"
              name="isAlive"
              value={formValues.isAlive ?? ''}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label="Alive"
              />
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label="Deceased"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* Hide / Unhide */}
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <FormLabel component="label" className="form-label">
                Hide
              </FormLabel>
            </Grid>
            <Grid item xs={9}>
              <Switch
                checked={formValues.isHide}
                onChange={handleChange}
                name="isHide"
              />
            </Grid>
          </Grid>
        </Grid>

        {!!formValues.spouse && !!formValues.totalSpouse?.length && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* Spouse Relationship */}
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="flex-end">
                  {formValues.totalSpouse.length > 1 && (
                    <Grid item xs={5}>
                      <FormControl component="fieldset" fullWidth>
                        <Grid container spacing={1}>
                          <Grid item>
                            <FormLabel>Select Spouse</FormLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <SelectInput
                              fullWidth={true}
                              variant="outlined"
                              label={'Select Spouse'}
                              name="selectedSpouse"
                              options={formValues.totalSpouse}
                              value={formValues.selectedSpouse}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={7}>
                    <FormControl component="fieldset" fullWidth>
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormLabel mLabel>Spouse Relationship</FormLabel>
                        </Grid>
                        <Grid item xs={12}>
                          <SelectInput
                            fullWidth={true}
                            variant="outlined"
                            label={'Spouse Relationship'}
                            name="spouseRelationship"
                            options={CONSTANTS.spouseRelationshipOptions}
                            value={formValues.spouseRelationship}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* Consanguinity status */}
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <FormLabel component="label" className="form-label">
                      Consanguineous
                    </FormLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={formValues.isConsanguineous}
                      onChange={handleChange}
                      name="isConsanguineous"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* diagnoses */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="margin-top-4"></div>
              <FormControl component="fieldset">
                <TextInput
                  label="Select Diagnoses"
                  type="multiple-select"
                  name="diagnoses"
                  value={formValues.diagnoses}
                  onChange={handleChange}
                  options={allDiagnoses}
                  mapper={diagnosesMap}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {formValues.diagnoses?.map((diagnosis) => {
                if (
                  diagnosis !== 'other' &&
                  !CONSTANTS.pregnanciesDiagnoses.includes(diagnosis)
                )
                  return (
                    <Grid item xs={12} key={diagnosis}>
                      <TextInput
                        label={`Age ${diagnosesMap[diagnosis]} Diagnosed`}
                        name={diagnosis}
                        value={
                          (formValues.diagnosesAge &&
                            formValues.diagnosesAge[diagnosis]) ||
                          ''
                        }
                        onChange={handleDiagnosisAgeChange}
                        updateParentOnBlur
                      />
                    </Grid>
                  );
                else return <></>;
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="margin-top-4"></div>
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            {/* mobile */}
            <Grid item xs={6}>
              <FormLabel component="label" className="form-label">
                Mobile
              </FormLabel>
              <TextField
                size="small"
                name="mobile"
                value={formValues.mobile}
                onChange={(e) => {
                  if (validatePhn(e.target.value) || e.target.value === '')
                    handleChange(e);
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                size="small"
                variant="outlined"
                onClick={handleClose}
                disableElevation
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                onClick={handleSubmit}
                size="small"
                variant="contained"
                color="secondary"
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default PersonMenu;
