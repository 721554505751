import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import WcIcon from '@mui/icons-material/Wc';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import ChatIcon from '@mui/icons-material/Chat';
import { ThemeContext, SnackContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';

function CustomNavIcon(props) {
  const classes = props.useStyles();

  const icon = props.icon;
  let iconComp = '';
  switch (icon) {
    case 'home':
      iconComp = <HomeIcon />;
      break;
    case 'generate-link':
      iconComp = <LinkIcon />;
      break;
    case 'families':
      iconComp = <AccountBoxIcon />;
      break;
    case 'pedigree-tool':
      iconComp = <AccountTreeIcon />;
      break;
    case 'settings':
      iconComp = <SettingsIcon />;
      break;
    case 'add-users':
      iconComp = <PersonAddIcon />;
      break;
    case 'person':
      iconComp = <PersonIcon />;
      break;
    case 'people':
      iconComp = <PeopleAltIcon />;
      break;
    case 'pregnant':
      iconComp = <PregnantWomanIcon />;
      break;
    case 'numbered-list':
      iconComp = <FormatListNumberedRtlIcon />;
      break;
    case 'wc':
      iconComp = <WcIcon />;
      break;
    case 'list-check':
      iconComp = <PlaylistAddCheckIcon />;
      break;
    case 'post-add':
      iconComp = <PostAddIcon />;
      break;
    case 'assignment':
      iconComp = <AssignmentIcon />;
      break;
    case 'chat':
      iconComp = <ChatIcon />;
      break;
    default:
      return '';
  }

  return <div className={classes.listIcon}>{iconComp}</div>;
}

export default function CustomDrawer({ navItems }) {
  const {
    theme: { appearance, accent }
  } = React.useContext(ThemeContext);
  // const { setSnack } = React.useContext(SnackContext);
  const [currentVersion, setCurrentVersion] = useState('');

  const useStyles = makeStyles((theme) => ({
    link: {
      textDecoration: 'none !important',
      color: '#000'
    },
    listItem: {
      textAlign: 'center',
      paddingBlock: 12,
      paddingInline: 3,
      '&:hover': {
        backgroundColor: accent + 'c4',
        textDecoration: 'none',
        color: '#fff'
      },

      [theme.breakpoints.up('sm')]: {
        paddingInline: 15
      }
    },
    drawer: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 64,
      backgroundColor: appearance.color,
      width: '100%',
      transition: `background-color 1s, color 1s`,
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        paddingTop: 0,
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    },

    drawerClose: {
      height: '100vh',
      '& > div': {
        overflow: 'hidden'
      },
      borderRight: `.5px solid #c1c1c170  `,
      backgroundColor: appearance.color,
      transition: `${theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })}, background-color 1s, color 1s`,
      whiteSpace: 'nowrap',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.down('lg')]: {
        zIndex: 100,
        position: 'fixed',
        bottom: 0,
        height: theme.spacing(7),
        width: '100%',
        display: 'flex'
      }
    },
    toolbar: {
      backgroundColor: appearance.color,
      transition: 'background-color 1s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    version: {
      position: 'fixed',
      bottom: '1rem',
      width: 'auto',
      [theme.breakpoints.down('lg')]: {
        bottom: '.4rem',
        right: '1rem'
      }
    },
    listIcon: {
      color: appearance.value === 'light' ? '#000' : '#ffff',
      minWidth: 40
    },
    '@global': {
      'a.drawer-item:hover > div > div': {
        color: '#fff'
      },
      ' a.selected > div': {
        backgroundColor: ` ${accent} !important`
      }
    }
  }));

  const classes = useStyles();

  const t = useAutoTranslation();

  const getCurrentVersion = () => {
    axios
      .get(APICONSTANTS.getCurrentVersion)
      .then(({ data }) => {
        if (data.current_version) setCurrentVersion(data.current_version);
      })
      .catch(() => {
        // setSnack({
        //   status: true,
        //   msg: 'Failed to fetch current version!',
        //   severity: 'error'
        // });
      });
  };

  useEffect(() => {
    getCurrentVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.drawerClose}>
      <div style={{ padding: 0.5, background: '#c1c1c170' }}>
        {/* <Divider  /> */}
      </div>
      <List component="div" disablePadding className={classes.drawer}>
        {navItems.map((item) => (
          <NavLink
            // exact
            strict
            key={item.icon}
            activeClassName="selected"
            to={item.link ?? item.path}
            id={item?.id}
            className={`${classes.link} drawer-item`}
          >
            <Tooltip title={t(item.label)} placement="right">
              <div className={classes.listItem}>
                {item.icon && (
                  <CustomNavIcon icon={item.icon} useStyles={useStyles} />
                )}
              </div>
            </Tooltip>
          </NavLink>
        ))}
        <ListItem className={classes.version}>
          <Typography
            color="inherit"
            variant="caption"
            display="block"
            gutterBottom
            className={classes.footer}
          >
            v{currentVersion}
          </Typography>
        </ListItem>
      </List>
    </div>
  );
}
