import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { useContext, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import TextInput from '../../UI/input/TextInput';
import { validateEmail } from 'src/util/util';
import axios from 'src/axios';
import { APICONSTANTS } from '../../Constants';
import { AppContext, SnackContext, UserContext } from 'src/store/ContextStore';
import { EVENTS } from 'src/components/admin/appReducer';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { CONSTANTS } from 'src/components/Constants';

const SetupPatientAccessMenu = ({ handleClose }) => {
  const { content, dispatchUpdate } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser } = users;
  const { user: loggedInUser } = useContext(UserContext);

  const t = useAutoTranslation();

  let isStudent = useMemo(() => {
    return loggedInUser.userType === CONSTANTS.userTypes.STUDENT;
  }, [loggedInUser]);

  const { setSnack } = useContext(SnackContext);
  const [errors, setErrors] = useState('');
  const [userDetails, setUserDetails] = useState({
    email: ''
  });
  const [isSetupPatient, setIsSetupPatient] = useState(false);
  const [loading, setLoading] = useState([false, false]);

  const handleChange = (e) => {
    setUserDetails((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const validateAndCheckUserExists = (email) => {
    // validating email
    if (!validateEmail(email)) {
      setErrors('Invalid email');
      return false;
    }

    return true;
  };

  const onSetupPatientAccess = (sendEmail) => {
    setLoading(sendEmail ? [true, false] : [false, true]);
    axios
      .put(
        APICONSTANTS.setupPatientAccess.replace(':user_id', selectedUser._id),
        { ...userDetails, sendEmail }
      )
      .then((res) => {
        if (res && res.data) {
          let value = {
            ...users,
            selectedUser: {
              ...selectedUser,
              email: userDetails.email
            }
          };

          dispatchUpdate({ type: EVENTS.SET_PEDIGREE_SELECTED_USER, value });
          setSnack({
            status: true,
            msg: 'Email added successfully',
            severity: 'success'
          });

          handleClose();
        }
      })
      .catch((error) => {
        setSnack({
          status: true,
          msg: error.response?.data?.error || 'An error has occurred!',
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading([false, false]);
      });
  };

  const onSubmit = (sendEmail) => {
    if (isSetupPatient && validateAndCheckUserExists(userDetails.email)) {
      // Add proband email
      onSetupPatientAccess(sendEmail);
    }
  };

  const handleSetupPatient = (e) => {
    setIsSetupPatient(e.target.checked);
  };

  return (
    <div style={{ maxWidth: 390 }}>
      <Grid container spacing={2}>
        {isSetupPatient && (
          <Grid item xs={12}>
            <TextInput
              label="E-mail"
              name="email"
              value={userDetails.email}
              onChange={handleChange}
              placeholder={t('Email')}
              updateParentOnBlur
              error={errors}
              helperText={errors}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="isSetupPatient"
                name="isSetupPatient"
                checked={isSetupPatient}
                onChange={handleSetupPatient}
              />
            }
            label={t('Collect family history and set up patient access')}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {!isStudent && (
              <Grid item xs={12}>
                <Button
                  endIcon={
                    loading[0] ? (
                      <CircularProgress size={15} />
                    ) : (
                      <EmailIcon size="small" />
                    )
                  }
                  color="secondary"
                  variant="contained"
                  disabled={!isSetupPatient || loading[0] || loading[1]}
                  onClick={() => onSubmit(true)}
                >
                  {t(`Add Email & Generate Link & send E-mail`)}
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                endIcon={
                  loading[1] ? (
                    <CircularProgress size={15} />
                  ) : (
                    <LinkIcon size="small" />
                  )
                }
                variant="contained"
                // color={isStudent ? 'secondary' : 'default'}
                color="secondary"
                onClick={() => onSubmit(false)}
                disabled={!isSetupPatient || loading[0] || loading[1]}
              >
                {isStudent ? t('Add Email') : t(`Add Email & Generate Link`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SetupPatientAccessMenu;
