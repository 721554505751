import React from 'react';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { useAutoTranslation } from 'src/hooks/useTranslate';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

//this is added to make options under the select
const ITEM_HEIGHT = 36;

const MenuProps = {
  getContentAnchorEl: null,
  // anchorOrigin: {
  // vertical: 'bottom',
  // horizontal: 'left'
  // },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5
    }
  },
  disableScrollLock: true
};

// supports only 1d values
// options e.g. ['a', 'b']
// mapper e.g. {'a': 'A', 'b': 'B'}
export default function MultipleSelect(props) {
  const theme = useTheme();

  const t = useAutoTranslation();

  const { value, name, onChange, options, variant, mapper } = props;

  return (
    <FormControl variant={variant ?? 'outlined'} fullWidth>
      <Select
        multiple
        value={value}
        onChange={onChange}
        inputProps={{
          name: name,
          id: name
        }}
        renderValue={(selected) => (
          <div className="chips">
            {selected.map((el) => (
              <Chip
                size="small"
                key={el}
                label={t(mapper[el])}
                className="chip"
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map((el) => (
          <MenuItem key={el} value={el} style={getStyles(el, value, theme)}>
            {t(mapper[el])}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
