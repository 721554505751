import { calculateAge } from 'src/util/util';
import { CONSTANTS } from '../Constants';
import {
  setTwinIdentifier,
  sortSingleBirthChildren
} from './PedigreeChart/utils';
let _labelsSet = {};

const defaultParents = [
  {
    relationship: 'mother',
    gender: 'female'
  },
  {
    relationship: 'father',
    gender: 'male'
  }
];

export const TWIN_TYPES = Object.freeze({
  identical: 'identical',
  nonIdentical: 'non-identical',
  unknown: 'unknown'
});

export const TWIN_IDENTIFIERS = Object.freeze({
  mztwin: 'mztwin',
  dztwin: 'dztwin',
  cztwin: 'cztwin'
});

const GENDER = Object.freeze({
  male: 'male',
  female: 'female',
  unknown: 'unknown'
});

const heights = 220;

// map of relations used in the pedigree
const RELATIONS = {
  children: 'children',
  siblings: 'siblings',
  halfSiblings: 'halfSiblings',
  partner: 'spouse',
  parent: 'parent',
  mothersParent: 'mothersParent',
  fathersParent: 'fathersParent',
  parentsSibling: 'parentsSibling',
  otherAffected: 'otherAffected'
};

export const diagnosesColorMap = [
  { type: 'breast-cancer', colour: '#ff6e6e' },
  { type: 'pancreatic-cancer', colour: '#f7e406' },
  { type: 'colon-cancer', colour: '#2baabf' },
  { type: 'ovarian-cancer', colour: '#f50257' },
  { type: 'prostate-cancer', colour: '#002884' },
  { type: 'polyps', colour: '#002114' },
  { type: 'heart-problems', colour: '#3f50b5' },
  { type: 'eye-problems', colour: '#ba000d' },
  { type: 'kidney-problems', colour: '#4caf50' },
  { type: 'neurological-conditions', colour: '#8b06f7' },
  { type: 'other', colour: '#f78706' },
  { type: 'blood-disorders', colour: '#ff0000' },
  { type: 'stillbirth', colour: '#253559' },
  { type: 'recurrent-misscarriage', colour: '#91e83a' },
  { type: 'congenital-abnormalities', colour: '#63f282' },
  { type: 'endometrial-cancer', colour: '#ffd480' },
  { type: 'hemophilia_a', colour: '#ff5e5e' },
  { type: 'hemophilia_b', colour: '#006FA2' },
  { type: 'fibrinogen_disorder', colour: '#ffa55e' },
  { type: 'von_willebrand_disease', colour: '#ffcc99' },
  { type: 'glanzmann_thrombasthenia', colour: '#ffebcc' },
  { type: 'bernard_soulier_syndrome', colour: '#f0fff0' },
  { type: 'hereditary_hemorrhagic_telangiectasia', colour: '#d1ffd1' },
  { type: 'platelet_function_disorder', colour: '#FAA000' },
  { type: 'neutropenia', colour: '#90C244' },
  { type: 'protein_c_deficiency', colour: '#F4D401' },
  { type: 'protein_s_deficiency', colour: '#58BF8D' },
  { type: 'antithrombin_iii_deficiency', colour: '#89F008' },
  { type: 'factor_v_leiden', colour: '#FC86FE' },
  { type: 'prothrombin_gene_variant', colour: '#A86B5C' },
  { type: 'low_platelet_count_(thrombocytopenia)', colour: '#FE8486' },
  { type: 'blood_clot_(thrombosis)', colour: '#EEA356' }
];

export const diagnsesShapeMap = {
  // 'breast-cancer': { shape: 'q1', color: '#ff4747' },
  // 'pancreatic-cancer': { shape: 'q2', color: '#f7e406' },
  // 'colon-cancer': { shape: 'q3', color: '#2baabf' },
  // 'ovarian-cancer': { shape: 'q4', color: '#f50257' },
  // 'prostate-cancer': { shape: 'dot', color: '#002884' },
  // 'heart-problems': { shape: 'q1', color: '#3f50b5' },
  // 'eye-problems': { shape: 'q2', color: '#ba000d' },
  // 'kidney-problems': { shape: 'q3', color: '#4caf50' },
  // 'neurological-conditions': { shape: 'q4', color: '#8b06f7' },
  // other: { shape: 'dot', color: '#f78706' },
  // 'blood-disorders': { shape: 'q1', color: '#ff0000' },
  // stillbirth: { shape: 'q2', color: '#253559' },
  // 'recurrent-misscarriage': {
  //   shape: 'q3',
  //   color: '#91e83a'
  // },
  // 'congenital-abnormalities': {
  //   shape: 'q4',
  //   color: '#63f282'
  // },
  // 'endometrial-cancer': { shape: 'dot', color: '#ffd480' }
  'breast-cancer': '#ff6e6e',
  'pancreatic-cancer': '#f7e406',
  'colon-cancer': '#2baabf',
  'ovarian-cancer': '#f50257',
  'prostate-cancer': '#002884',
  'heart-problems': '#3f50b5',
  polyps: '#002114',
  'eye-problems': '#ba000d',
  'kidney-problems': '#4caf50',
  'neurological-conditions': '#8b06f7',
  other: '#f78706',
  'blood-disorders': '#ff0000',
  stillbirth: '#253559',
  'recurrent-misscarriage': '#91e83a',
  'congenital-abnormalities': '#63f282',
  'endometrial-cancer': '#ffd480',
  hemophilia_a: '#ff5e5e',
  hemophilia_b: '#006FA2',
  fibrinogen_disorder: '#ffa55e',
  von_willebrand_disease: '#ffcc99',
  glanzmann_thrombasthenia: '#ffebcc',
  bernard_soulier_syndrome: '#f0fff0',
  hereditary_hemorrhagic_telangiectasia: '#d1ffd1',
  platelet_function_disorder: '#FAA000',
  neutropenia: '#90C244',
  protein_c_deficiency: '#F4D401',
  protein_s_deficiency: '#58BF8D',
  antithrombin_iii_deficiency: '#89F008',
  factor_v_leiden: '#FC86FE',
  prothrombin_gene_variant: '#A86B5C',
  'low_platelet_count_(thrombocytopenia)': '#FE8486',
  'blood_clot_(thrombosis)': '#EEA356'
};

// map of all relations - coming from fhq
export const relationMap = {
  self: RELATIONS.siblings,
  mother: RELATIONS.parent,
  father: RELATIONS.parent,
  son: RELATIONS.children,
  daughter: RELATIONS.children,
  fullSister: RELATIONS.siblings,
  fullBrother: RELATIONS.siblings,
  halfSister: RELATIONS.halfSiblings,
  halfBrother: RELATIONS.halfSiblings,
  spouse: RELATIONS.partner,
  mothersMother: RELATIONS.mothersParent,
  mothersFather: RELATIONS.mothersParent,
  fathersMother: RELATIONS.fathersParent,
  fathersFather: RELATIONS.fathersParent,
  mothersBrother: RELATIONS.parentsSibling,
  mothersSister: RELATIONS.parentsSibling,
  fathersBrother: RELATIONS.parentsSibling,
  fathersSister: RELATIONS.parentsSibling,
  otherAffected: RELATIONS.otherAffected
};

// offset used for the editor's position
export const offset = 500;
export const boxSize = { w: '100%', h: 950 };

export const generateNewUser = (
  gender,
  user_id,
  relation = 'otherAffected'
) => {
  const user = {
    firstName: ' ',
    pid: crypto.randomUUID(),
    relationship: relation,
    diagnoses: [],
    gender: gender,
    user_id: user_id
  };

  return user;
};

export const relationship = {
  mother: 'mother',
  father: 'father',
  son: 'son',
  daughter: 'daughter',
  fullSister: 'fullSister',
  fullBrother: 'fullBrother',
  halfSister: 'halfSister',
  halfBrother: 'halfBrother',
  spouse: 'spouse',
  mothersMother: 'mothersMother',
  mothersFather: 'mothersFather',
  fathersMother: 'fathersMother',
  fathersFather: 'fathersFather',
  mothersBrother: 'mothersBrother',
  mothersSister: 'mothersSister',
  fathersBrother: 'fathersBrother',
  fathersSister: 'fathersSister',
  otherAffected: 'otherAffected'
};

export const defaultPedigreeConfig = {
  targetDiv: 'pedigreeChart',
  width: boxSize.w,
  height: boxSize.h,
  symbol_size: 45,
  fontSize: 16,
  zoomSrc: ['wheel'],
  zoomIn: 0.1,
  zoomOut: 2.0,
  diseases: diagnosesColorMap
};

export const validatePedigree = (user, family) => {
  // const members = [user, ...family]

  const relation = {
    father: null,
    halfFather: null,
    mother: null,
    halfMother: null,
    son: [],
    daughter: [],
    fullSister: [],
    fullBrother: [],
    halfSister: [],
    halfBrother: [],
    spouse: null,
    mothersMother: null,
    mothersFather: null,
    fathersMother: null,
    fathersFather: null,
    mothersBrother: [],
    mothersSister: [],
    fathersBrother: [],
    fathersSister: [],
    otherAffected: []
  };

  const members = [];
  family.forEach((person) => {
    person.pid = person.pid ? person.pid : person._id;

    person.gender = person.genderIdentity
      ? CONSTANTS.genderMappings[person.genderIdentity]
      : person.gender || 'unknown';
    // if (!person.mother && !person.father && person.top_level) {
    if (person.isTwin && person.twinMember) {
      const fam = [...family, user];
      const twinMember = fam.find((p) => p.pid === person.twinMember);
      if (twinMember) {
        // setting twin identifiers for person and twin member
        setTwinIdentifier(family, person, twinMember, person.twinType);
      }
    }
    if (relation[person.relationship] === null)
      relation[person.relationship] = person;
    else relation[person.relationship].push(person);
    // }
  });

  if (
    relation.mother ||
    relation.father
    // && !relation.mother.top_level &&
    // !relation.mother.mother
  ) {
    if (!relation.mother)
      relation.mother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.mother
      );
    if (!relation.father)
      relation.father = generateNewUser(
        GENDER.male,
        user._id,
        relationship.father
      );
    relation.mother.spouse = relation.father.pid;
    relation.father.spouse = relation.mother.pid;
    if (
      !relation.fathersFather &&
      !relation.fathersMother &&
      !relation.mothersFather &&
      !relation.mothersMother
    ) {
      relation.mother.top_level = 'true';
      relation.father.top_level = 'true';
    }
    user.mother = relation.mother.pid;
    user.father = relation.father.pid;
  }

  // validating fathers parents along with adding dummy user if need
  if (
    relation.fathersMother ||
    relation.fathersFather
    // && !relation.fathersMother?.top_level &&
    // !relation.fathersMother?.mother
  ) {
    if (!relation.fathersMother)
      relation.fathersMother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.fathersMother
      );
    if (!relation.fathersFather)
      relation.fathersFather = generateNewUser(
        GENDER.male,
        user._id,
        relationship.fathersFather
      );
    relation.fathersMother.spouse = relation.fathersFather.pid;
    relation.fathersFather.spouse = relation.fathersMother.pid;

    relation.fathersMother.top_level = 'true';
    relation.fathersFather.top_level = 'true';

    if (relation.father) {
      relation.father.mother = relation.fathersMother.pid;
      relation.father.father = relation.fathersFather.pid;
      if (!relation.mothersFather && !relation.mothersMother) {
        relation.mother.mother = relation.fathersMother.pid;
        relation.mother.father = relation.fathersFather.pid;
        relation.mother.noparents = 'true';
      }
    } else {
      relation.mother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.mother
      );

      relation.father = generateNewUser(
        GENDER.male,
        user._id,
        relationship.father
      );
      relation.mother.spouse = relation.father.pid;
      relation.father.spouse = relation.mother.pid;
      user.mother = relation.mother.pid;
      user.father = relation.father.pid;

      relation.father.mother = relation.fathersMother.pid;
      relation.father.father = relation.fathersFather.pid;
      delete relation.father.noparents;

      relation.mother.mother = relation.fathersMother.pid;
      relation.mother.father = relation.fathersFather.pid;
      relation.mother.noparents = 'true';
    }
  }

  if (
    relation.mothersMother ||
    relation.mothersFather
    // && !relation.mothersMother.top_level &&
    // !relation.mothersMother.mother
  ) {
    if (!relation.mothersMother)
      relation.mothersMother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.mothersMother
      );
    if (!relation.mothersFather)
      relation.mothersFather = generateNewUser(
        GENDER.male,
        user._id,
        relationship.mothersFather
      );
    relation.mothersMother.spouse = relation.mothersFather.pid;
    relation.mothersFather.spouse = relation.mothersMother.pid;

    relation.mothersMother.top_level = 'true';
    relation.mothersFather.top_level = 'true';

    if (relation.mother) {
      relation.mother.mother = relation.mothersMother.pid;
      relation.mother.father = relation.mothersFather.pid;
      delete relation.mother.noparents;
      if (!relation.fathersFather) {
        relation.father.mother = relation.mothersMother.pid;
        relation.father.father = relation.mothersFather.pid;
        relation.father.noparents = 'true';
      }
    } else {
      relation.mother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.mother
      );

      relation.father = generateNewUser(
        GENDER.male,
        user._id,
        relationship.father
      );
      relation.mother.spouse = relation.father.pid;
      relation.father.spouse = relation.mother.pid;
      user.mother = relation.mother.pid;
      user.father = relation.father.pid;

      relation.mother.mother = relation.mothersMother.pid;
      relation.mother.father = relation.mothersFather.pid;
      delete relation.mother.noparents;

      if (!relation.fathersFather) {
        relation.father.mother = relation.mothersMother.pid;
        relation.father.father = relation.mothersFather.pid;
        relation.father.noparents = 'true';
      }
    }
  }

  // checking fahters parents exist when siblings are present else  creating parents
  if (
    (relation.fathersBrother.length > 0 || relation.fathersSister.length > 0) &&
    !relation.fathersFather &&
    !relation.fathersMother
  ) {
    relation.fathersMother = generateNewUser(
      GENDER.female,
      user._id,
      relationship.fathersMother
    );

    relation.fathersFather = generateNewUser(
      GENDER.male,
      user._id,
      relationship.fathersFather
    );
    relation.fathersMother.spouse = relation.fathersFather.pid;
    relation.fathersFather.spouse = relation.fathersMother.pid;

    if (!relation.father) {
      relation.mother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.mother
      );

      relation.father = generateNewUser(
        GENDER.male,
        user._id,
        relationship.father
      );

      user.mother = relation.mother.pid;
      user.father = relation.father.pid;

      relation.father.father = relation.fathersFather.pid;
      relation.father.mother = relation.fathersMother.pid;
      delete relation.father.noparents;
      relation.mother.father = relation.fathersFather.pid;
      relation.mother.mother = relation.fathersMother.pid;
      relation.mother.noparents = 'true';
    } else {
      relation.father.father = relation.fathersFather.pid;
      relation.father.mother = relation.fathersMother.pid;
      delete relation.father.noparents;
    }

    if (!relation.mothersMother) {
      relation.mother.father = relation.fathersFather.pid;
      relation.mother.mother = relation.fathersMother.pid;
      relation.mother.noparents = 'true';
    }

    relation.fathersMother.top_level = 'true';
    relation.fathersFather.top_level = 'true';
  }

  if (relation.fathersBrother.length > 0) {
    relation.fathersBrother.map((fbro) => {
      fbro.mother = relation.fathersMother.pid;
      fbro.father = relation.fathersFather.pid;
    });
  }

  if (relation.fathersSister.length > 0) {
    relation.fathersSister.map((fsis) => {
      fsis.mother = relation.fathersMother.pid;
      fsis.father = relation.fathersFather.pid;
    });
  }

  // checking mothers parents exist when siblings are present else  creating parents
  if (
    (relation.mothersBrother.length > 0 || relation.mothersSister.length > 0) &&
    !relation.mothersFather &&
    !relation.mothersMother
  ) {
    relation.mothersMother = generateNewUser(
      GENDER.female,
      user._id,
      relationship.mothersMother
    );

    relation.mothersFather = generateNewUser(
      GENDER.male,
      user._id,
      relationship.mothersFather
    );
    relation.mothersMother.spouse = relation.mothersFather.pid;
    relation.mothersFather.spouse = relation.mothersMother.pid;

    if (!relation.mother) {
      relation.mother = generateNewUser(
        GENDER.female,
        user._id,
        relationship.mother
      );

      relation.father = generateNewUser(
        GENDER.male,
        user._id,
        relationship.father
      );

      user.mother = relation.mother.pid;
      user.father = relation.father.pid;

      relation.mother.father = relation.mothersFather.pid;
      relation.mother.mother = relation.mothersMother.pid;
      relation.father.father = relation.mothersFather.pid;
      relation.father.mother = relation.mothersMother.pid;
      relation.father.noparents = 'true';
    } else {
      relation.mother.father = relation.mothersFather.pid;
      relation.mother.mother = relation.mothersMother.pid;
      delete relation.mother.noparents;
      delete relation.mother.top_level;
      delete relation.father.top_level;
    }

    if (!relation.fathersMother) {
      relation.father.father = relation.mothersFather.pid;
      relation.father.mother = relation.mothersMother.pid;
      relation.father.noparents = 'true';
      delete relation.father.top_level;
    }

    relation.mothersMother.top_level = 'true';
    relation.mothersFather.top_level = 'true';
  }

  if (relation.mothersBrother.length > 0) {
    relation.mothersBrother.map((mbro) => {
      mbro.mother = relation.mothersMother.pid;
      mbro.father = relation.mothersFather.pid;
    });
  }

  if (relation.mothersSister.length > 0) {
    relation.mothersSister.map((msis) => {
      msis.mother = relation.mothersMother.pid;
      msis.father = relation.mothersFather.pid;
    });
  }

  // validating siblings and add parents if parents dosents exists
  if (
    (relation.fullBrother.length > 0 ||
      relation.fullSister.length > 0 ||
      relation.halfBrother.length > 0 ||
      relation.halfSister.length > 0) &&
    (!relation.mother || !relation.father)
  ) {
    relation.mother = generateNewUser(
      GENDER.female,
      user._id,
      relationship.mother
    );

    relation.father = generateNewUser(
      GENDER.male,
      user._id,
      relationship.father
    );
    relation.mother.spouse = relation.father.pid;
    relation.father.spouse = relation.mother.pid;
    user.mother = relation.mother.pid;
    user.father = relation.father.pid;
    if (
      !relation.fathersFather &&
      !relation.fathersMother &&
      !relation.mothersFather &&
      !relation.mothersMother
    ) {
      relation.mother.top_level = 'true';
      relation.father.top_level = 'true';
    }
  }

  if (relation.fullBrother.length > 0) {
    if (relation.mother) {
      relation.fullBrother.map((bro) => {
        bro.mother = relation.mother.pid;
        bro.father = relation.father.pid;
      });
    }
  }
  if (relation.fullSister.length > 0) {
    if (relation.mother) {
      relation.fullSister.map((sis) => {
        sis.mother = relation.mother.pid;
        sis.father = relation.father.pid;
      });
    }
  }

  // validatin half sibling relation with parents and half parents

  if (relation.halfSister.length > 0 || relation.halfBrother.length > 0) {
    const shf = relation.halfSister.some(
      (sis) => sis.halfParent === relation.mother.pid
    );
    const shm = relation.halfSister.some(
      (sis) => sis.halfParent === relation.father.pid
    );

    const bhf = relation.halfBrother.some(
      (bro) => bro.halfParent === relation.mother.pid
    );
    const bhm = relation.halfBrother.some(
      (bro) => bro.halfParent === relation.father.pid
    );

    if (shf || bhf) {
      relation.halfFather = generateNewUser(GENDER.male, user._id);
      relation.halfFather.spouse = relation.mother.pid;
      if (relation.mother.top_level) relation.halfFather.top_level = 'true';
      else {
        if (relation.mother.father && relation.mother.mother) {
          relation.halfFather.father = relation.mother.father;
          relation.halfFather.mother = relation.mother.mother;
        }
        relation.halfFather.noparents = 'true';
      }
    }
    if (shm || bhm) {
      relation.halfMother = generateNewUser(GENDER.female, user._id);
      if (relation.father.top_level) relation.halfMother.top_level = 'true';
      else {
        relation.halfMother.noparents = 'true';
        if (relation.father.father && relation.father.mother) {
          relation.halfMother.father = relation.father.father;
          relation.halfMother.mother = relation.father.mother;
        }
      }
    }
  }

  if (relation.halfSister.length > 0) {
    if (relation.mother) {
      relation.halfSister.map((sis) => {
        sis.mother =
          sis.halfParent === relation.mother.pid
            ? relation.mother.pid
            : relation.halfMother?.pid;

        sis.father =
          sis.halfParent === relation.father.pid
            ? relation.father.pid
            : relation.halfFather?.pid;
      });
    }
  }

  if (relation.halfBrother.length > 0) {
    if (relation.mother) {
      relation.halfBrother.map((bro) => {
        bro.mother =
          bro.halfParent === relation.mother.pid
            ? relation.mother.pid
            : relation.halfMother?.pid;

        bro.father =
          bro.halfParent === relation.father.pid
            ? relation.father.pid
            : relation.halfFather?.pid;
      });
    }
  }

  if (
    relation.spouse
    // &&
    // !relation.father &&
    // !relation.spouse.top_level &&
    // !relation.spouse.father
  ) {
    user.spouse = relation.spouse.pid;
    relation.spouse.spouse = user.pid;
    relation.spouse.gender =
      user.gender === GENDER.male ? GENDER.female : GENDER.male;
    if (relation.father) {
      relation.spouse.father = relation.father.pid;
      relation.spouse.mother = relation.mother.pid;
      relation.spouse.noparents = 'true';
    } else {
      user.top_level = 'true';
      relation.spouse.top_level = 'true';
    }
  }

  if (
    (relation.son.length > 0 || relation.daughter.length > 0) &&
    !relation.spouse
  ) {
    relation.spouse = generateNewUser(
      user.gender === GENDER.female ? GENDER.male : GENDER.female,
      user._id,
      RELATIONS.partner
    );
    user.spouse = relation.spouse.pid;
    relation.spouse.spouse = relation.spouse.pid;
    if (!relation.father) {
      user.top_level = 'true';
      relation.spouse.top_level = 'true';
    } else {
      relation.spouse.father = relation.father.pid;
      relation.spouse.mother = relation.mother.pid;
      relation.spouse.noparents = true;
    }
  }

  if (relation.son.length > 0) {
    if (relation.spouse) {
      const father_pid =
        relation.spouse.gender === GENDER.male ? relation.spouse.pid : user.pid;
      const mother_pid =
        relation.spouse.gender === GENDER.female
          ? relation.spouse.pid
          : user.pid;
      relation.son.map((son) => {
        son.mother = mother_pid;
        son.father = father_pid;
      });
    }
  }

  if (relation.daughter.length > 0) {
    if (relation.spouse) {
      const father_pid =
        relation.spouse.gender === GENDER.male ? relation.spouse.pid : user.pid;
      const mother_pid =
        relation.spouse.gender === GENDER.female
          ? relation.spouse.pid
          : user.pid;
      relation.daughter.map((dau) => {
        dau.mother = mother_pid;
        dau.father = father_pid;
      });
    }
  }

  if (relation.otherAffected.length > 0) {
    relation.otherAffected.map((p) => {
      p.top_level = 'true';
    });
  }

  const siblings = [];

  Object.keys(relation).map((rl) => {
    if (relation[rl]) {
      if (!Array.isArray(relation[rl])) members.push(relation[rl]);
      else {
        if (rl === relationship.fullBrother || rl === relationship.fullSister) {
          siblings.push(...relation[rl]);
        } else members.push(...relation[rl]);
      }
    }
  });
  // sorting siblings to keep twins lie next to other
  const sibs = sortSingleBirthChildren([...siblings, user]);

  if (
    relation.son.length === 0 &&
    relation.daughter.length === 0 &&
    relation.spouse
  ) {
    const child = generateNewUser('male');
    child.mother = relation.spouse.pid;
    child.father = user.pid;
    child.not_set = true;
    child.hidden = true;
    members.push(child);
  }

  return [...members, ...sibs];
};

const findUPN = (user) => {
  let upn = 1;
  if (!user.proBandId) {
    if (user.familyMemberId) {
      const _familyMemberId = user.familyMemberId.split('-');
      upn = parseInt(_familyMemberId[_familyMemberId.length - 1]);
    } else upn = '';
  }
  return upn;
};
