import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const SnackBar = (props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(props.open);

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    //setOpen(false);
    props.setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert onClose={handleClose} severity={props.severity}>
            {props.message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
};

export default SnackBar;
