import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CONSTANTS } from '../Constants';
import { Grid } from '@mui/material';
import PedigreeBox from './PedigreeBox';
import { AppContext } from 'src/store/ContextStore';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: { position: 'absolute', top: 0, left: 0 }
});

function PedigreeChartView({ patient }) {
  const classes = useStyles();
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;

  const history = useHistory();

  if (Object.keys(users.selectedUser).length === 0)
    history.push(CONSTANTS.pedigreeToolPath);

  return (
    <Grid container className={classes.container}>
      {(users.family.length ||
        Object.keys(users.selectedUser).length !== 0) && (
        <PedigreeBox patient={patient} />
      )}
    </Grid>
  );
}

export default PedigreeChartView;
