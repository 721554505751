import React from 'react';
import { APICONSTANTS, HPO_CONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import axios from 'src/axios';
import { Box, CircularProgress, Grid, Chip } from '@mui/material';
import { AddCircleRounded, AutoAwesome, MoreHoriz } from '@mui/icons-material';
import InfoTooltip from 'src/components/UI/addons/InfoTooltip';

function Suggestions({ data }) {
  const { setSnack } = React.useContext(SnackContext);

  let familyMemberId = React.useMemo(() => {
    return data?.data?.proBandId || data?.data?.familyMemberId;
  }, [data]);

  const [fetchData, setFetchData] = React.useState({
    hpoTerms: [],
    Genes: [],
    Diseases: []
  });

  const addSuggestion = (type, value) => {
    if (!type || !value) return;
    let new_values = Array.from(new Set([...fetchData[type], value]));
    setFetchData((prev) => ({
      ...prev,
      [type.toString()]: new_values
    }));

    let api = {
      Genes: { key: 'genotypes', value: 'geneId' },
      Diseases: { key: 'diagnoses', value: 'diseaseId' }
    };

    const payload = {
      user_id: data?.data?._id || data?.data?.user_id,
      familyMemberId: familyMemberId,
      [api[type].value]: new_values
    };

    axios
      .post(APICONSTANTS[api[type].key], payload)
      .then((res) => {
        if (res.data?.success) {
          setSnack({
            status: true,
            msg: `Added to ${[api[type].key]}`.toLocaleUpperCase(),
            severity: 'success'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        })
      );
  };

  const getHPOTerm = () => {
    if (!familyMemberId) return;
    Promise.all([
      axios.get(
        APICONSTANTS.getGenotypesById.replace(':familyMemberId', familyMemberId)
      ),
      axios.get(
        APICONSTANTS.getDiagnosesById.replace(':familyMemberId', familyMemberId)
      ),
      axios.get(
        APICONSTANTS.getPhenotypesById.replace(
          ':familyMemberId',
          familyMemberId
        )
      )
    ])
      .then(([geno_res, diagno_res, pheno_res]) =>
        setFetchData({
          Genes: geno_res?.data.genotypes?.map((gene) => gene.id),
          Diseases: diagno_res.data.diagnoses.map((dig) => dig.id),
          hpoTerms: pheno_res?.data.phenotypes.map((hp) => hp.id)
        })
      )
      .catch((err) => {
        setSnack({
          status: true,
          msg: 'Error fetching Values',
          severity: 'error'
        });
      });
  };

  React.useEffect(() => {
    getHPOTerm();
  }, []);

  return (
    <Grid container spacing={1}>
      {fetchData.hpoTerms.length === 0 && (
        <Grid
          xs={12}
          sx={{
            gap: 1,
            alignItems: 'center',
            display: 'flex',
            fontSize: '.8rem',
            fontWeight: 'bold',
            bgcolor: '#ffcce684',
            p: 1,
            borderRadius: 1
          }}
        >
          <AutoAwesome fontSize="small" />
          <span>Add Phenotypes to receive suggestions</span>
        </Grid>
      )}
      {fetchData.hpoTerms.map((id) => {
        return (
          <SuggestionsBox
            key={id}
            hpoTermId={id}
            addSuggestion={addSuggestion}
          />
        );
      })}
    </Grid>
  );
}

export default Suggestions;

const SuggestionsBox = ({ hpoTermId, addSuggestion }) => {
  const [max, setMax] = React.useState({ genes: 5, diseases: 5 });
  const [data, setData] = React.useState({
    genes: [],
    diseases: []
  });

  const hpo_api = `${HPO_CONSTANTS['hpoBaseURL']}/annotation/${hpoTermId}`;

  const fetchMore = (type) => {
    setMax((prev) => ({ ...prev, [type]: prev[type] + 5 }));
  };

  React.useMemo(() => {
    axios.get(hpo_api).then((hpo_res) =>
      setData({
        genes: hpo_res.data.genes,
        diseases: hpo_res.data.diseases
      })
    );
  }, []);

  let hpo_associations = [
    { type: 'Genes', name: 'genes', id: 'id', value: 'name' },
    {
      type: 'Diseases',
      name: 'diseases',
      id: 'id',
      value: 'id',
      info: 'name'
    }
  ];

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          bgcolor: '#ffcce644',
          p: 1,
          borderRadius: 1,
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            gap: 1,
            alignItems: 'center',
            display: 'flex',
            fontSize: '.8rem',
            fontWeight: 'bold'
          }}
        >
          <AutoAwesome fontSize="small" />
          <span>Associated Genes & Diseases : {hpoTermId}</span>
        </Box>
        {hpo_associations.map((term) => {
          return (
            <Box
              key={term.type}
              sx={{ display: 'flex', flexDirection: 'column', gap: 0.7 }}
            >
              <Box sx={{ px: 1, fontSize: '.8rem', fontWeight: 'bold' }}>
                {term.type}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.7,
                  alignItems: 'center'
                }}
              >
                {data[term.name].slice(0, max[term.name]).map((res) => {
                  return (
                    <DataChip
                      term={term}
                      key={res[term.id]}
                      res={res}
                      addSuggestion={addSuggestion}
                    />
                  );
                })}
                <Chip
                  label={<MoreHoriz fontSize="small" color="action" />}
                  size="small"
                  onClick={() => fetchMore(term.name)}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
};

const DataChip = ({ term, res, addSuggestion }) => {
  const [loading, setLoading] = React.useState(false);

  const onClick = async (type, data) => {
    setLoading(true);
    await addSuggestion(type, data);
    setLoading(false);
  };
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '.8rem',
        fontWeight: 'bold',
        gap: 0.8,
        bgcolor: 'white',
        cursor: 'pointer'
      }}
      onClick={() => onClick(term.type, res[term.id])}
    >
      {term.info && <InfoTooltip label={res[term.info]} fontSize="0.5rem" />}
      <span>{res[term.value]}</span>
      {loading ? (
        <CircularProgress size={15} />
      ) : (
        <AddCircleRounded color="info" fontSize="0.5rem" />
      )}
    </Box>
  );
};
