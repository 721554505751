// ContextAPI
import React from 'react';
import { FormData, PopupContext } from '../../../../../CreateQuestionnaire';

// Libraries
import { Fragment, useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

// Components
import TextField from '@mui/material/TextField';

// styles
import styles from './TextInput.module.css';
import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { FORM_TYPES } from '../../../../../constants/formTypes';
import GraphStructureService from '../../../../../services/graph.structurer.service';
// import ReactQuill from 'react-quill';

import Editor from './Editor';

const initialValidationSchema = yup.object({
  label: yup
    .string('Enter the form label')
    .required('This field must be specified')
});

function EmbedContent({ edit }) {
  // ContextAPI
  const [formData, setFormData] = useContext(FormData);
  const [popupContext, setPopupContext] = useContext(PopupContext);
  const gs = new GraphStructureService();

  const initialFormValues = () => {
    let value = {};
    value = popupContext.data.formData || {
      label: ''
    };
    return value;
  };

  // handleSubmit
  const handleFormSubmit = async (values) => {
    values.type = FORM_TYPES.EMBED_CONTENT;
    const updatedFormData = { ...formData };
    if (!edit) {
      values.id = uuidv4();
      updatedFormData.schema[popupContext.data.id].formElements.push(values);
      setPopupContext({ ...popupContext, show: false });
    } else {
      const formElements = formData.schema[popupContext.data.id].formElements;
      for (let i = 0; i < formElements.length; i++) {
        if (formElements[i].id === popupContext.data.formData.id) {
          values.id = formElements[i].id;
          formElements[i] = values;
          gs.editFormElement(
            values.id,
            popupContext.data.id,
            updatedFormData,
            values,
            true
          );
        }
      }
      updatedFormData.schema[popupContext.data.id].formElements = formElements;
      setFormData(updatedFormData);
      setPopupContext({ ...popupContext, show: false });
    }
  };

  const handleClose = async () => {
    setPopupContext({ ...popupContext, show: false });
  };

  // Formik
  const formik = useFormik({
    initialValues: initialFormValues(),
    validationSchema: initialValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    }
  });
  const [value, setValue] = React.useState(formik.values['label'] || '');

  React.useMemo(() => {
    formik.setFieldValue('label', value);
  }, [value]);
  return (
    <Fragment>
      <div className={styles.InitialFormScreen} onSubmit={handleFormSubmit}>
        <h2>Embed Content</h2>
        <p>
          In the rendered form the below the text will be rendered like a HTML
          content so you can write both normal text as well custom HTML
        </p>
        <Grid
          style={{
            border: '1px solid black',
            maxHeight: 500,
            overflowY: 'scroll'
          }}
        >
          {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
          <Editor value={value} onChange={setValue} />
        </Grid>
        {/* <form onSubmit={formik.handleSubmit} className={styles.InitialForm}>
          <TextField
            variant="outlined"
            className={styles.FormField}
            id="label"
            name="label"
            label="Content"
            multiline
            rows={2}
            maxRows={5}
            value={formik.values['label']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched['label'] && Boolean(formik.errors['label'])}
            helperText={formik.touched['label'] && formik.errors['label']}
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              CANCEL
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
            >
              {edit ? 'SAVE' : 'CREATE'}
            </Button>
          </div>
        </form> */}
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            CANCEL
          </Button>
          <Button
            onClick={formik.handleSubmit}
            variant="contained"
            color="secondary"
          >
            {edit ? 'SAVE' : 'CREATE'}
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default EmbedContent;
