import {
  Box,
  Button,
  Grid,
  Grow,
  List,
  ListItemButton,
  Popper,
  TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AddCircle, ArrowRight } from '@mui/icons-material';
import React, { useState, useMemo, useRef, useContext } from 'react';
import { TWIN_TYPES, generateNewUser, relationship } from '../pedigreeUtil';
import { GENDER } from '../../questionnaire/Constants';
import { CONSTANTS } from '../../Constants';
import { set_Cache } from '../PedigreeChart/cache';
import {
  getSpousesWithChildren,
  getOrganizeTwinData
} from '../PedigreeChart/utils';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import SelectSpouseModal from './SelectSpouseModal';
import { ThemeContext } from 'src/store/ContextStore';

const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'end',
    gap: 6,
    height: 60
  },
  textInputContainer: {
    height: 16
  },
  button: {
    width: 200,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const REQUIRE_UNSAVED_NODES = true;

function AddRelativeMenu({
  menu,
  user,
  data,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  dataset,
  setPedigreeState,
  setError
}) {
  const classes = useStyles();

  const [sisterCount, setSisterCount] = useState(1);
  const [brotherCount, setBrotherCount] = useState(1);
  const [daughterCount, setDaughterCount] = useState(1);
  const [sonCount, setSonCount] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [showTwinMenu, setShowTwinMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const addingChildNodeRef = useRef(null);
  const { theme } = useContext(ThemeContext);

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  // finding all connected spouses with their children for the node
  const spousesWithChildren = useMemo(() => {
    if (data.data) {
      return getSpousesWithChildren(data.data, deepCopy, REQUIRE_UNSAVED_NODES);
    }
  }, [data.data, deepCopy]);

  const hideAddChildrenBtn = useMemo(() => {
    // not running for single spouse
    if (spousesWithChildren?.length <= 1) return false;
    // checking for unsaved node
    const hasUnsavedNode = spousesWithChildren.some((node) => !node?.label);
    const isMissingUpnOrProBandId = !data.data?.upn && !data.data?.proBandId;

    return hasUnsavedNode || isMissingUpnOrProBandId;
  }, [spousesWithChildren, data.data]);

  let isNodeHaveParents = useMemo(() => {
    return !(data?.data?.noparents === 'true' || data?.data?.top_level);
  }, [data]);

  const updateData = (dataset) => {
    set_Cache(JSON.stringify(dataset), user?.familyId);
    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset));
    });
    setDataset(dataset);
  };

  function getAllSiblings(person) {
    const siblings = dataset.map(function (p) {
      if (
        p.pid !== person.pid &&
        p.noparents &&
        p.mother === person.mother &&
        p.father === person.father &&
        p.id > person.id
      )
        return p.pid;
    });
    return siblings.filter((p) => !!p);
  }

  const generateParents = (node) => {
    const { data, depth } = node;
    if (data.noparents) {
      let node_sibs = getAllSiblings(data);

      const spouse = dataset.filter((p) => p.pid === data.spouse)[0];

      const person = deepCopy.filter((p) => p.pid === data.pid)[0];
      const siblings = deepCopy.filter((p) => node_sibs.includes(p.pid));
      const others = deepCopy.filter(
        (p) => !node_sibs.includes(p.pid) && p.pid !== data.pid
      );

      const connectedMother = deepCopy.filter((p) => p.pid === data.mother)[0];

      const father = generateNewUser('male', user.pid);
      const mother = generateNewUser('female', user.pid);
      father.spouse = mother.pid;
      mother.spouse = father.pid;
      if (data.pid === user.pid) {
        father.relationship = relationship.father;
        mother.relationship = relationship.mother;
      } else if (data.relationship === relationship.father) {
        father.relationship = relationship.fathersFather;
        mother.relationship = relationship.fathersMother;
      } else if (data.relationship === relationship.mother) {
        father.relationship = relationship.mothersFather;
        mother.relationship = relationship.mothersMother;
      }

      if (connectedMother.top_level) {
        father.top_level = 'true';
        mother.top_level = 'true';
      } else {
        father.father = connectedMother.father;
        father.mother = connectedMother.mother;
        mother.father = connectedMother.father;
        mother.mother = connectedMother.mother;
        father.noparents = 'true';
        mother.noparents = 'true';
      }

      delete person.noparents;
      person.father = father.pid;
      person.mother = mother.pid;

      siblings.map((sib) => {
        sib.father = father.pid;
        sib.mother = mother.pid;
      });

      const orderId = dataset.find((p) => p.pid === person.pid);

      let newDataset = [...others, father, mother, ...siblings, person];
      if (
        orderId?.id < spouse?.id &&
        spouse.relationship === relationship.mother
      ) {
        newDataset = [father, mother, ...others, ...siblings, person];
      }

      updateData(newDataset);
    }
    if (data.top_level) {
      //find all top levels
      const toplevels = deepCopy.filter((p) => p.top_level === 'true');
      const others = deepCopy.filter((p) => p.top_level !== 'true');
      const father = generateNewUser('male', user._id);
      const mother = generateNewUser('female', user._id);
      if (data.pid === user.pid) {
        father.relationship = relationship.father;
        mother.relationship = relationship.mother;
      }
      if (data.relationship === relationship.father) {
        father.relationship = relationship.fathersFather;
        mother.relationship = relationship.fathersMother;
      }
      if (data.relationship === relationship.mother) {
        father.relationship = relationship.mothersFather;
        mother.relationship = relationship.mothersMother;
      }
      father.spouse = mother.pid;
      mother.spouse = father.pid;
      father.top_level = 'true';
      mother.top_level = 'true';

      toplevels.forEach((element) => {
        delete element.top_level;
        element.father = father.pid;
        element.mother = mother.pid;
        if (element.pid !== data.pid) element.noparents = 'true';
      });

      const newDataset = [father, mother, ...toplevels, ...others];
      updateData(newDataset);
    }
    handleClose();
  };

  const generateGrandParents = (node) => {
    const { data } = node;
    const toplevels = deepCopy.filter((p) => p.top_level === 'true');

    if (data.top_level) {
      const others = deepCopy.filter((p) => p.top_level !== 'true');
      const father = generateNewUser('male', user._id);
      const mother = generateNewUser('female', user._id);

      father.spouse = mother.pid;
      mother.spouse = father.pid;

      const fatherFather = generateNewUser('male', user._id);
      const fatherMother = generateNewUser('female', user._id);

      fatherFather.spouse = fatherMother.pid;
      fatherMother.spouse = fatherFather.pid;

      const motherFather = generateNewUser('male', user._id);
      const motherMother = generateNewUser('female', user._id);

      if (data.pid === user.pid) {
        father.relationship = relationship.father;
        mother.relationship = relationship.mother;
        fatherFather.relationship = relationship.fathersFather;
        fatherMother.relationship = relationship.fathersMother;
        motherFather.relationship = relationship.mothersFather;
        motherMother.relationship = relationship.mothersMother;
      }

      motherFather.spouse = motherMother.pid;
      motherMother.spouse = motherFather.pid;

      fatherFather.top_level = 'true';
      fatherMother.top_level = 'true';

      father.father = fatherFather.pid;
      father.mother = fatherMother.pid;

      motherFather.top_level = 'true';
      motherMother.top_level = 'true';

      mother.father = motherFather.pid;
      mother.mother = motherMother.pid;

      toplevels.forEach((element) => {
        delete element.top_level;
        element.father = father.pid;
        element.mother = mother.pid;
        if (element.pid !== data.pid) element.noparents = 'true';
      });

      const newDataset = [
        fatherFather,
        fatherMother,
        motherFather,
        motherMother,
        father,
        mother,
        ...toplevels,
        ...others
      ];

      updateData(newDataset);
    }

    if (data.noparents) {
      let node_sibs = getAllSiblings(data);

      const person = deepCopy.filter((p) => p.pid === data.pid)[0];
      const siblings = deepCopy.filter((p) => node_sibs.includes(p.pid));
      const others = deepCopy.filter(
        (p) => !node_sibs.includes(p.pid) && p.pid !== data.pid
      );

      const connectedMother = deepCopy.filter((p) => p.pid === data.mother)[0];
      const connectedGrandMother = deepCopy.filter(
        (p) => p.pid === connectedMother.mother
      )[0];

      const father = generateNewUser('male', user._id);
      const mother = generateNewUser('female', user._id);

      father.spouse = mother.pid;
      mother.spouse = father.pid;

      const fatherFather = generateNewUser('male', user._id);
      const fatherMother = generateNewUser('female', user._id);

      fatherFather.spouse = fatherMother.pid;
      fatherMother.spouse = fatherFather.pid;

      const motherFather = generateNewUser('male', user._id);
      const motherMother = generateNewUser('female', user._id);

      if (data.pid === user.pid) {
        father.relationship = relationship.father;
        mother.relationship = relationship.mother;
        fatherFather.relationship = relationship.fathersFather;
        fatherMother.relationship = relationship.fathersMother;
        motherFather.relationship = relationship.mothersFather;
        motherMother.relationship = relationship.mothersMother;
      }

      motherFather.spouse = motherMother.pid;
      motherMother.spouse = motherFather.pid;

      father.father = fatherFather.pid;
      father.mother = fatherMother.pid;

      mother.father = motherFather.pid;
      mother.mother = motherMother.pid;

      if (connectedMother && connectedMother.top_level) {
        fatherFather.top_level = 'true';
        fatherMother.top_level = 'true';
        motherFather.top_level = 'true';
        motherMother.top_level = 'true';

        toplevels.forEach((element) => {
          // establishing connected-mother relation
          element.mother = motherMother.pid;
          element.father = motherFather.pid;
          delete element.top_level;
          element.noparents = 'true';
        });
      } else {
        if (connectedGrandMother.top_level) {
          fatherFather.top_level = 'true';
          fatherMother.top_level = 'true';
          motherFather.top_level = 'true';
          motherMother.top_level = 'true';
        } else {
          // connecting parent pid's
          fatherFather.father = connectedGrandMother.father;
          fatherFather.mother = connectedGrandMother.mother;
          fatherMother.mother = connectedGrandMother.mother;
          fatherMother.father = connectedGrandMother.father;
          motherFather.father = connectedGrandMother.father;
          motherFather.mother = connectedGrandMother.mother;
          motherMother.father = connectedGrandMother.father;
          motherMother.mother = connectedGrandMother.mother;

          fatherFather.noparents = 'true';
          fatherMother.noparents = 'true';

          motherFather.noparents = 'true';
          motherMother.noparents = 'true';
        }
      }
      delete person.noparents;
      person.father = father.pid;
      person.mother = mother.pid;

      siblings.map((sib) => {
        sib.father = father.pid;
        sib.mother = mother.pid;
      });

      const newDataset = [
        ...others,
        fatherFather,
        fatherMother,
        motherFather,
        motherMother,
        father,
        mother,
        ...siblings,
        person
      ];
      updateData(newDataset);
    }
    handleClose();
  };

  const generateSpouse = (node, fromChild = false, child_gender) => {
    const { data } = node;
    const person = deepCopy.filter((p) => p.pid === data.pid)[0];
    const others = deepCopy.filter((p) => p.pid !== data.pid);

    const spouse = generateNewUser(
      data.gender === GENDER.female
        ? GENDER.male
        : data.gender === GENDER.male
        ? GENDER.female
        : GENDER.unknown,
      user._id
    );
    spouse.mother = data.mother;
    spouse.father = data.father;
    if (data.pid === user.pid) {
      spouse.relationship = relationship.spouse;
    }
    spouse.noparents = 'true';
    if (data.top_level) {
      spouse.top_level = 'true';
      delete spouse.noparents;
    }

    const children = [];
    if (fromChild) {
      const count = child_gender === 'male' ? sonCount : daughterCount;
      for (let index = 0; index < count; index++) {
        const child = generateNewUser(child_gender, user._id);
        child.mother = data.gender === 'female' ? data.pid : spouse.pid;
        child.father = data.gender === 'female' ? spouse.pid : data.pid;

        children.push(child);
      }
    } else {
      const child = generateNewUser('male', user._id);
      child.mother = data.gender === 'female' ? data.pid : spouse.pid;
      child.father = data.gender === 'female' ? spouse.pid : data.pid;
      child.not_set = 'true';
      child.hidden = 'true';
      children.push(child);
    }
    person.spouse = spouse.pid;
    spouse.spouse = person.pid;

    const newDataset = [...others, person, spouse, ...children];
    const organisedDataset = getOrganizeTwinData(newDataset);
    updateData(organisedDataset);
    handleClose();
  };

  const generateChildren = (gender, node) => {
    const { data } = node;
    let parentRelationship;
    let isParentConsanguineous = false;
    if (data.gender === GENDER.unknown) {
      let spouse = deepCopy.find((p) => p.pid === data.spouse);
      if (!spouse) {
        setError(
          'There is no sperm/egg donor. Please change gender before adding offspring.'
        );
        return;
      }
    }
    if (!data.spouse) {
      generateSpouse(node, true, gender);
    }
    const spouse = deepCopy.find((p) => p.pid === data.spouse);

    const hiddenChild = deepCopy.filter(
      (p) =>
        ((p.father === data.pid && p.mother === data.spouse) ||
          (p.mother === data.pid && p.father === data.spouse)) &&
        p.not_set
    )[0];

    let others = deepCopy;

    if (hiddenChild) {
      others = deepCopy.filter((p) => p.pid !== hiddenChild.pid);
      parentRelationship = hiddenChild.parentRelationship;
      isParentConsanguineous = hiddenChild.isParentConsanguineous || false;
    }

    // Real existing child
    const existingChild = deepCopy.find(
      (p) =>
        ((p.father === data.pid && p.mother === data.spouse) ||
          (p.mother === data.pid && p.father === data.spouse)) &&
        !p.not_set &&
        !!p?.parentRelationship
    );

    if (existingChild) {
      parentRelationship = existingChild.parentRelationship;
      isParentConsanguineous = existingChild.isParentConsanguineous || false;
    }
    if (spouse) {
      if (
        (data.gender === GENDER.female &&
          [GENDER.female, GENDER.unknown].includes(spouse.gender)) ||
        (spouse.gender === GENDER.female &&
          [GENDER.female, GENDER.unknown].includes(data.gender))
      ) {
        setError(
          'There is no sperm donor in this relationship. Please change gender before adding offspring.'
        );
        return;
      }

      const relationValid =
        (data.gender === GENDER.female && spouse.gender === GENDER.male) ||
        (spouse.gender === GENDER.female && data.gender === GENDER.male);

      if (!relationValid) {
        setError(
          'There is no egg donor in this relationship. Please change gender before adding offspring.'
        );
        return;
      }
    }

    if (data.spouse) {
      if (gender === 'female') {
        const daughters = [];

        for (let index = 0; index < daughterCount; index++) {
          const daughter = generateNewUser(gender, user._id);
          daughter.mother = data.gender === 'female' ? data.pid : data.spouse;
          daughter.father = data.gender === 'male' ? data.pid : data.spouse;
          if (parentRelationship) {
            daughter.parentRelationship = parentRelationship;
            daughter.isParentConsanguineous = isParentConsanguineous;
          }
          if (data.pid === user.pid) {
            daughter.relationship = relationship.daughter;
          } else if (
            data.relationship === relationship.father ||
            data.relationship === relationship.mother
          ) {
            daughter.relationship = relationship.fullSister;
          } else if (
            data.relationship === relationship.mothersFather ||
            data.relationship === relationship.mothersMother
          ) {
            daughter.relationship = relationship.mothersSister;
          } else if (
            data.relationship === relationship.fathersFather ||
            data.relationship === relationship.fathersMother
          ) {
            daughter.relationship = relationship.fathersSister;
          }

          daughters.push(daughter);
        }

        const newDataset = [...others, ...daughters];
        const organisedDataset = getOrganizeTwinData(newDataset);
        updateData(organisedDataset);
      }
      if (gender === 'male') {
        const sons = [];

        for (let index = 0; index < sonCount; index++) {
          const son = generateNewUser(gender, user._id);
          son.mother = data.gender === 'female' ? data.pid : data.spouse;
          son.father = data.gender === 'male' ? data.pid : data.spouse;
          if (parentRelationship) {
            son.parentRelationship = parentRelationship;
            son.isParentConsanguineous = isParentConsanguineous;
          }
          if (data.pid === user.pid) {
            son.relationship = relationship.son;
          } else if (
            data.relationship === relationship.father ||
            data.relationship === relationship.mother
          ) {
            son.relationship = relationship.fullBrother;
          } else if (
            data.relationship === relationship.mothersFather ||
            data.relationship === relationship.mothersMother
          ) {
            son.relationship = relationship.mothersBrother;
          } else if (
            data.relationship === relationship.fathersFather ||
            data.relationship === relationship.fathersMother
          ) {
            son.relationship = relationship.fathersBrother;
          }

          sons.push(son);
        }

        const newDataset = [...others, ...sons];
        const organisedDataset = getOrganizeTwinData(newDataset);
        updateData(organisedDataset);
      }
    }
    handleClose();
  };

  const generateSiblings = (gender, node, twinType) => {
    const { data } = node;
    if (data.father && data.mother) {
      if (gender === 'female') {
        const sisters = [];
        for (let index = 0; index < sisterCount; index++) {
          const sister = generateNewUser(gender, user._id);
          sister.mother = data.mother;
          sister.father = data.father;
          if (twinType) {
            sister.isTwin = true;
            sister.twinMember = data.pid;
            sister.twinType = twinType;
          }
          if (data.parentRelationship) {
            sister.parentRelationship = data.parentRelationship;
            sister.isParentConsanguineous =
              data.isParentConsanguineous || false;
          }

          if (data.pid === user.pid) {
            sister.relationship = relationship.sister;
          }
          if (data.relationship === relationship.father) {
            sister.relationship = relationship.fathersSister;
          }
          if (data.relationship === relationship.mother) {
            sister.relationship = relationship.mothersSister;
          }

          sisters.push(sister);
        }

        const newDataset = [...deepCopy, ...sisters];
        const organisedDataset = getOrganizeTwinData(newDataset);
        updateData(organisedDataset);
      }
      if (gender === 'male') {
        const brothers = [];
        for (let index = 0; index < brotherCount; index++) {
          const brother = generateNewUser(gender, user._id);
          brother.mother = data.mother;
          brother.father = data.father;
          if (data.parentRelationship) {
            brother.parentRelationship = data.parentRelationship;
            brother.isParentConsanguineous =
              data.isParentConsanguineous || false;
          }
          if (data.pid === user.pid) {
            brother.relationship = relationship.brother;
          }
          if (data.relationship === relationship.father) {
            brother.relationship = relationship.fathersBrother;
          }
          if (data.relationship === relationship.mother) {
            brother.relationship = relationship.mothersBrother;
          }
          if (twinType) {
            brother.isTwin = true;
            brother.twinMember = data.pid;
            brother.twinType = twinType;
          }
          brothers.push(brother);
        }

        const newDataset = [...deepCopy, ...brothers];
        const organisedDataset = getOrganizeTwinData(newDataset);
        updateData(organisedDataset);
      }
    }
    handleClose();
  };

  // for generating children to the selected spouse
  const handleSelectSpouse = (spouse) => {
    const { node, gender } = addingChildNodeRef.current;

    // updating spouse
    const updatedNode = {
      ...node,
      data: {
        ...node.data,
        spouse: spouse
      }
    };
    generateChildren(gender, updatedNode);
    handleCloseModal();
  };

  const addChildrenHandler = (childGender, node) => {
    // adding direct children to the node
    if (spousesWithChildren?.length <= 1) {
      generateChildren(childGender, node);
    } else {
      // for adding children to selected node
      addingChildNodeRef.current = { gender: childGender, node: node };
      handleOpenModal();
    }
  };

  const SiblingMenu = ({ data, gender }) => {
    return (
      <Popper
        open={showTwinMenu}
        anchorEl={anchorEl.currentTarget}
        placement="right"
      >
        <Grow
          in={data}
          style={{
            transformOrigin: 'top bottom'
          }}
        >
          <Box
            sx={{
              bgcolor: theme.appearance.color,
              borderRadius: 2,
              border: '1px #777 solid'
            }}
          >
            <List>
              <ListItemButton onClick={() => generateSiblings(gender, data)}>
                Singleton
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  generateSiblings(gender, data, TWIN_TYPES.identical)
                }
              >
                Monozygotic
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  generateSiblings(gender, data, TWIN_TYPES.nonIdentical)
                }
              >
                Dizygotic
              </ListItemButton>
              <ListItemButton
                onClick={() =>
                  generateSiblings(gender, data, TWIN_TYPES.unknown)
                }
              >
                Unknown
              </ListItemButton>
            </List>
          </Box>
        </Grow>
      </Popper>
    );
  };

  return (
    <>
      <CustomDialog
        title={'Select Spouse'}
        open={openModal}
        handleClose={handleCloseModal}
        minHeight="auto"
      >
        <SelectSpouseModal
          spousesWithChildren={spousesWithChildren}
          handleSelect={handleSelectSpouse}
        />
      </CustomDialog>
      {!menu ? (
        <Grid container spacing={2}>
          {!isNodeHaveParents && (
            <>
              <Grid item xs={12} className={classes.buttonGroup}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    generateParents(data);
                  }}
                >
                  <span style={{ marginInline: 10 }}>Parent(s)</span>
                  <AddCircle fontSize="small" />
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.buttonGroup}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    generateGrandParents(data);
                  }}
                >
                  <span style={{ marginInline: 10 }}>Grand Parent(s)</span>
                  <AddCircle fontSize="small" />
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12} className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => generateSpouse(data)}
            >
              <span style={{ marginInline: 10 }}>Spouse</span>
              <AddCircle fontSize="small" />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            <TextField
              type={'number'}
              inputProps={{
                min: 1,
                max: 10,
                className: classes.textInputContainer
              }}
              variant="outlined"
              value={sisterCount}
              onChange={(e) => setSisterCount(e.target.value)}
              disabled={!isNodeHaveParents}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                generateSiblings('female', data);
              }}
              disabled={!isNodeHaveParents}
            >
              <span style={{ marginInline: 10 }}>Sister(s)</span>{' '}
              <AddCircle fontSize="small" />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            <TextField
              type={'number'}
              inputProps={{
                min: 1,
                max: 10,
                className: classes.textInputContainer
              }}
              variant="outlined"
              value={brotherCount}
              onChange={(e) => setBrotherCount(e.target.value)}
              disabled={!isNodeHaveParents}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                generateSiblings('male', data);
              }}
              disabled={!isNodeHaveParents}
            >
              <span style={{ marginInline: 10 }}>Brother(s)</span>{' '}
              <AddCircle fontSize="small" />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            <TextField
              type={'number'}
              inputProps={{
                min: 1,
                max: 10,
                className: classes.textInputContainer
              }}
              variant="outlined"
              value={daughterCount}
              disabled={hideAddChildrenBtn}
              onChange={(e) => setDaughterCount(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={hideAddChildrenBtn}
              onClick={() => addChildrenHandler('female', data)}
            >
              <span style={{ marginInline: 10 }}>Daughter(s)</span>{' '}
              <AddCircle fontSize="small" />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.buttonGroup}>
            <TextField
              type={'number'}
              inputProps={{
                min: 1,
                max: 10,
                className: classes.textInputContainer
              }}
              variant="outlined"
              value={sonCount}
              disabled={hideAddChildrenBtn}
              onChange={(e) => setSonCount(e.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={hideAddChildrenBtn}
              onClick={() => addChildrenHandler('male', data)}
            >
              <span style={{ marginInline: 10 }}>Son(s)</span>{' '}
              <AddCircle fontSize="small" />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <List>
          {!isNodeHaveParents && (
            <>
              <ListItemButton onClick={() => generateParents(data)}>
                Parents
              </ListItemButton>
              <ListItemButton onClick={() => generateGrandParents(data)}>
                Grand Parents
              </ListItemButton>
            </>
          )}
          <ListItemButton onClick={() => generateSpouse(data)}>
            Spouse
          </ListItemButton>
          <ListItemButton
            disabled={!isNodeHaveParents}
            onClick={(e) => {
              setAnchorEl({
                currentTarget: e.currentTarget,
                gender: 'male'
              });
              setShowTwinMenu(true);
            }}
          >
            Brother <ArrowRight />
          </ListItemButton>
          <ListItemButton
            disabled={!isNodeHaveParents}
            onClick={(e) => {
              setAnchorEl({
                currentTarget: e.currentTarget,
                gender: 'female'
              });
              setShowTwinMenu(true);
            }}
          >
            Sister <ArrowRight />
          </ListItemButton>

          <ListItemButton
            disabled={hideAddChildrenBtn}
            onClick={() => {
              addChildrenHandler('male', data);
            }}
          >
            Son
          </ListItemButton>
          <ListItemButton
            disabled={hideAddChildrenBtn}
            onClick={() => {
              addChildrenHandler('female', data);
            }}
          >
            Daughter
          </ListItemButton>
        </List>
      )}

      {showTwinMenu && <SiblingMenu data={data} gender={anchorEl.gender} />}
    </>
  );
}

export default AddRelativeMenu;
