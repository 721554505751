import React, { useState, useEffect, useContext, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios';
import { Button, CircularProgress, Grid } from '@mui/material';
import { APICONSTANTS, HPO_CONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import { getHPOSearchURL } from 'src/util/util';

const GenotypesMenu = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(HPO_CONSTANTS['initialPage']);
  const [loading, setLoading] = useState(false);
  const { setSnack } = useContext(SnackContext);

  let familyMemberId = useMemo(() => {
    return data?.data?.proBandId || data?.data?.familyMemberId;
  }, [data]);

  const fetchDataByGenes = async (currentPage) => {
    if (searchTerm.length >= 3) {
      let url = getHPOSearchURL(
        searchTerm,
        currentPage,
        HPO_CONSTANTS.category['gene']
      );

      axios
        .get(url)
        .then(({ data }) => {
          const { results = [], totalCount } = data || {};

          const newOptions = results.map((d) => ({
            id: d.id,
            name: d.name
          }));

          if (!currentPage) {
            setOptions([...newOptions]);
          } else {
            setOptions((preOption) => [...preOption, ...newOptions]);
          }

          setTotalCount(totalCount);
          setPage(currentPage);
        })
        .catch((err) => {
          console.log('ERRRR-', err);
        });
    } else {
      setOptions([]);
    }
  };

  const getGenotypes = () => {
    if (!familyMemberId) return;

    axios
      .get(
        APICONSTANTS.getGenotypesById.replace(':familyMemberId', familyMemberId)
      )
      .then(({ data }) => {
        const { genotypes = [] } = data || {};
        setSelectedItems(genotypes);
      })
      .catch((err) => {
        console.log('ERRRR-', err);
      });
  };

  useEffect(() => {
    fetchDataByGenes(HPO_CONSTANTS['initialPage']);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    getGenotypes();
    // eslint-disable-next-line
  }, []);

  const handleSelect = (event, value) => {
    if (value) {
      const uniqueData = value.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setSelectedItems([...uniqueData]);
      setSearchTerm('');
    }
  };

  const onSave = () => {
    if (!familyMemberId)
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree first!',
        severity: 'error'
      });

    setLoading(true);
    const payload = {
      user_id: data?.data?._id || data?.data?.user_id,
      familyMemberId: familyMemberId,
      geneId: selectedItems.map((item) => item.id)
    };

    axios
      .post(APICONSTANTS.genotypes, payload)
      .then((res) => {
        if (res.data?.success) {
          setSnack({
            status: true,
            msg: 'Genotypes saved successfully',
            severity: 'success'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response.data.error ?? 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      options.length < totalCount
    ) {
      let newCurrentPage = page + 1;
      fetchDataByGenes(newCurrentPage);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            clearOnBlur={false}
            id="tags-outlined"
            options={options}
            value={selectedItems}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            ListboxProps={{ onScroll: handleScroll }}
            filterOptions={(options, state) => options}
            // filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for Genes"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            endIcon={loading ? <CircularProgress size={15} /> : <></>}
            variant="contained"
            color="secondary"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GenotypesMenu;
