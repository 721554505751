import {
  Box,
  Button,
  Modal,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../UI/input/TextInput';
import { validateEmail } from 'src/util/util';
// import { Alert } from '@mui/lab';
import axios from 'src/axios';
import { APICONSTANTS } from '../Constants';
import { AppContext, SnackContext, ThemeContext } from 'src/store/ContextStore';
import { EVENTS } from 'src/components/admin/appReducer';
import { useAutoTranslation } from 'src/hooks/useTranslate';

function AddFamily({ open, setOpen }) {
  const { theme } = useContext(ThemeContext);
  const style = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    top: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 400,
    bgcolor: theme.appearance.color,
    boxShadow: 24,
    p: 3,
    borderRadius: 2
  };

  const {
    content: { pedigreeUsers },
    dispatchUpdate
  } = useContext(AppContext);

  const { setSnack } = useContext(SnackContext);

  const [errors, setErrors] = useState('');

  const t = useAutoTranslation();

  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [isSetupPatient, setIsSetupPatient] = useState(false);

  const handleClose = () => {
    setErrors('');
    setUserDetails({ firstName: '', lastName: '', email: '' });
    setOpen(false);
  };

  const handleChange = (e) => {
    setUserDetails((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const validateAndCheckUserExists = (email) => {
    // validating email
    if (!validateEmail(email)) {
      setErrors('Invalid email');
      return false;
    }
    // Check if user already Exists
    return true;
  };

  const onAddFamily = () => {
    axios
      .post(APICONSTANTS.createFamily, userDetails)
      .then((res) => {
        if (!res.data.user_exists && res.data.user) {
          dispatchUpdate({
            type: EVENTS.SET_PEDIGREE_USERS,
            value: [res.data.user, ...pedigreeUsers]
          });
          handleClose();
          setSnack({
            status: true,
            msg: 'Created New Family ',
            severity: 'success'
          });
        } else {
          handleClose();
          setSnack({
            status: true,
            msg: 'User email already exists ',
            severity: 'error'
          });
        }
      })
      .catch((/*err*/) => {
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        });
      });
  };

  const onSubmit = () => {
    if (isSetupPatient) {
      if (validateAndCheckUserExists(userDetails.email)) {
        // Add family with email
        onAddFamily();
      }
    } else {
      // Add family without email
      onAddFamily();
    }
  };

  const handleSetupPatient = (e) => {
    setIsSetupPatient(e.target.checked);
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {t(`Proband Personal Details`)}
          </Typography>
          {/* <Alert severity="info">Proband already exist</Alert> */}

          {isSetupPatient && (
            <TextInput
              label="E-mail"
              name="email"
              value={userDetails.email}
              onChange={handleChange}
              placeholder={t('Email')}
              updateParentOnBlur
              error={errors}
              helperText={errors}
            />
          )}
          <TextInput
            label="First Name"
            name="firstName"
            placeholder={t('First name')}
            value={userDetails.firstName}
            onChange={handleChange}
            updateParentOnBlur
          />
          <TextInput
            label="Last Name"
            name="lastName"
            placeholder={t('Last name')}
            value={userDetails.lastName}
            onChange={handleChange}
            updateParentOnBlur
          />

          <FormControlLabel
            control={
              <Checkbox
                id="isSetupPatient"
                name="isSetupPatient"
                checked={isSetupPatient}
                onChange={handleSetupPatient}
              />
            }
            label={t('Collect family history and set up patient access')}
          />
          <div
            style={{
              display: 'flex',
              paddingTop: 10,
              justifyContent: 'space-between'
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              {t('Cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={onSubmit}>
              {t(`Add`)}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default AddFamily;
