/**
 * Simple table definitions using material table
 * There is no actions column, it should be passed from the parent
 * In-built features: search, sorting & pagination
 */

import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import DetailPanel from './DetailPanel';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function Table(props) {
  const { theme } = React.useContext(ThemeContext);

  const onRowClick = (event, rowData, togglePanel) => {
    if (props.isCollapsible) {
      // if (props.shouldToggle && props.previousSelectedId === rowData.tableData.id) {
      //     props.setPreviousSelectedId(-1)
      // }
      // only make a call if no box rows present - temp sol
      // props.onRowClick(rowData);
      return togglePanel();
    } else if (props.onRowClick) return props.onRowClick(rowData);
    return undefined;
  };

  const useRowStyles = makeStyles({
    tablecontainer: {
      overflowx: 'initial'
    },
    addButton: {
      position: 'absolute',
      top: 15,
      left: 15
    },
    tableHover: {
      '& tbody>.MuiTableRow-root:hover': {
        // cursor: "pointer",
        backgroundColor: `${theme.appearance.shade} !important`,
        color: `${
          theme.appearance.value === 'light' ? '#000' : '#ffff'
        } !important`
      },
      // '& table>.MuiTableCell-root ': {
      '& tr>.MuiTableCell-root ': {
        border: 'none'
      },

      '& .MuiIcon-root,.MuiTypography-caption,.MuiInputBase-root': {
        color: theme.appearance.value !== 'light' && 'white'
      }
    }
  });
  const classes = useRowStyles();

  const rowColor = {
    midnight: ['#37384a', '#323645c7'],
    dark: ['#2d2d2f', '#343436']
  };

  const getRowColor = (rowData) => {
    if (
      props.highlightOnSelect &&
      rowData.tableData.id === props.selectedTableDataId
    )
      return props.highlightColor;
    return rowData.tableData.id % 2 === 0
      ? theme.appearance.value === 'light'
        ? '#FFFFFF'
        : rowColor[theme.appearance.value][0]
      : theme.appearance.value === 'light'
      ? '#F8F9FA'
      : rowColor[theme.appearance.value][1];
  };

  const t = useAutoTranslation();
  return (
    <div className={classes.tableHover}>
      <MaterialTable
        className={classes.tablecontainer}
        style={{
          backgroundColor: theme.appearance.color,
          color: theme.appearance.text
        }}
        bordered={false}
        tableRef={props.tableRef ?? undefined}
        title={props.title && t(props.title)}
        columns={props.columns}
        data={props.rows}
        options={{
          padding: props.dense ? 'dense' : 'default',
          pageSize: props.pageSize ? props.pageSize : 3,
          pageSizeOptions: props.pageSizeOptions || [3, 5, 8, 10, 15, 20],
          headerStyle: {
            fontWeight: 'bold',
            fontSize: '1rem',
            color: '#f8f9fa',
            backgroundColor: theme.accent
            // backgroundColor: '#C9DAF9'
          },
          paging: props.hidePaging ? false : true,
          search: props.disableSearch ? false : true,
          rowStyle: (rowData) => ({
            backgroundColor: getRowColor(rowData)
          })
        }}
        // show add option only if add click function is present
        components={
          props.addClicked
            ? {
                Toolbar: (tprops) => (
                  <>
                    <MTableToolbar {...tprops} />
                    <Button
                      style={props.buttonStyle}
                      disabled={props.disableAddBtn}
                      variant="contained"
                      color="secondary"
                      onClick={() => props.addClicked()}
                      className={classes.addButton}
                      endIcon={<AddCircleIcon fontSize="small" />}
                      size={props.addBtnSize ?? undefined}
                    >
                      {t(props.addText)}
                    </Button>
                  </>
                )
              }
            : {}
        }
        detailPanel={
          props.isCollapsible
            ? (rowData) => (
                <DetailPanel
                  boxKeys={props.boxKeys}
                  columns={props.boxColumns}
                  rowData={rowData}
                  onRowClick={props.onRowClick}
                  getRowColor={getRowColor}
                />
              )
            : undefined
        }
        onRowClick={props.onRowClick ? onRowClick : undefined}
      />
    </div>
  );
}
