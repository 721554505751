import React from 'react';
import Grid from '@mui/material/Grid';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import SelectBar from 'src/components/pedigree/SelectBar';
// import Divider from '@mui/material/Divider';
// import PedigreeBox from 'src/components/pedigree/PedigreeBox';
// import { AppContext } from 'src/store/ContextStore';

export default function PedigreePanel({ patient }) {
  // const { content } = React.useContext(AppContext);
  // const { pedigreeSelectedUsers: users } = content;

  return (
    <Grid container>
      <HeaderTitle text={'Pedigree Tool'} />
      <Grid item xs={12}>
        <SelectBar patient={patient} />
      </Grid>
      {/* {(users.family.length ||
        Object.keys(users.selectedUser).length !== 0) && (
        <Grid item container xs={11}>
          <Grid item xs={12}>
            <div className="margin-top-2 margin-bottom-2">
              <Divider />
            </div>
          </Grid>
          <Grid item xs={12}>
            <PedigreeBox />
          </Grid>
        </Grid>
      )} */}
    </Grid>
  );
}
