import React from 'react';
import { Box, Grow, List, ListItemButton, Popper } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
import AddRelativeMenu from '../pedigree-sidebar/AddRelativeMenu';
import { AppContext, ThemeContext } from 'src/store/ContextStore';
import FertilityMenu from './FertilityMenu';
import GenderMenu from './GenderMenu';
import AdoptedMenu from './AdoptedMenu';
import Comment from './Comment';
import EditText from './EditText';

export default function Menu({
  node,
  onCloseMenu,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  dataset,
  setPedigreeState,
  setError
}) {
  const [subMenu, setSubMenu] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    setSubMenu(false);
  }, [node]);

  const toggleSubMenu = (e, { val }) => {
    setAnchorEl(e.currentTarget);
    setSubMenu({ val });
  };

  const {
    content: {
      pedigreeSelectedUsers: { selectedUser }
    }
  } = React.useContext(AppContext);

  const { theme } = React.useContext(ThemeContext);

  const menu = React.useMemo(() => {
    switch (subMenu.val) {
      case 1:
        return (
          <AddRelativeMenu
            menu={true}
            setPedigreeState={setPedigreeState}
            user={selectedUser}
            data={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            dataset={dataset}
            setError={setError}
            onCloseMenu={onCloseMenu}
          />
        );
      case 2:
        return (
          <GenderMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
            dataset={dataset}
            setError={setError}
          />
        );
      case 3:
        return (
          <AdoptedMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
          />
        );
      case 4:
        return (
          <FertilityMenu
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
          />
        );
      case 5:
        return (
          <Comment
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
          />
        );
      case 6:
        return (
          <EditText
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
          />
        );

      default:
        return false;
    }
  }, [subMenu, node]);

  return (
    <>
      {node && (
        <Grow
          in={node}
          style={{
            transformOrigin: 'top bottom'
          }}
        >
          <Box
            width={150}
            sx={{
              bgcolor: theme.appearance.color,
              borderRadius: 2,
              border: '1px #777 solid'
            }}
          >
            <List>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 1 })}>
                Add Relation <ArrowRight />
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 2 })}>
                Gender
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 3 })}>
                Adopted
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 4 })}>
                Fertility
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 5 })}>
                Person Note
              </ListItemButton>
              <ListItemButton onClick={(e) => toggleSubMenu(e, { val: 6 })}>
                Edit Text
              </ListItemButton>
              <ListItemButton disabled>Hide</ListItemButton>
              <ListItemButton disabled>Delete</ListItemButton>
            </List>
          </Box>
        </Grow>
      )}
      <Popper
        open={!!subMenu && !!node}
        anchorEl={anchorEl}
        placement="auto-start"
      >
        <Grow
          in={node}
          style={{
            transformOrigin: 'top bottom'
          }}
        >
          <Box
            sx={{
              bgcolor: theme.appearance.color,
              borderRadius: 2,
              border: '1px #777 solid'
            }}
          >
            {menu}
          </Box>
        </Grow>
      </Popper>
    </>
  );
}

// const GenderMenu = ({
//   node,
//   deepCopy,
//   setDataset,
//   setDeepCopy,
//   handleClose,
//   setPedigreeState,
//   setError
// }) => {
//   const { theme } = React.useContext(ThemeContext);
//   const { data } = node;

//   const gender = data.genderIdentity ?? data.gender;

//   const onChange = (value) => {
//     const { data } = node;

//     const idx = deepCopy.findIndex((person) => data.pid === person.pid);

//     const relationValid = checkRelationValid(
//       CONSTANTS.genderMappings[value],
//       deepCopy,
//       data
//     );

//     if (!relationValid.status) {
//       setError(relationValid.message);
//       return;
//     }

//     deepCopy[idx] = {
//       ...deepCopy[idx],
//       genderIdentity: value,
//       gender: CONSTANTS.genderMappings[value]
//     };

//     setPedigreeState(CONSTANTS.pedigreeState.modified);
//     setDeepCopy((deepCopy) => {
//       return JSON.parse(JSON.stringify(deepCopy));
//     });
//     setDataset(deepCopy);
//     handleClose();
//   };

//   return (
//     <List>
//       {Q_CONSTANTS.genderIdentityOptions.map((option, idx) => {
//         return (
//           <ListItemButton
//             key={idx}
//             style={{
//               backgroundColor:
//                 option.value.toLowerCase() === gender.toLowerCase() &&
//                 theme.buttonStyle
//             }}
//             onClick={() => onChange(option.value)}
//           >
//             {' '}
//             {option.value}{' '}
//           </ListItemButton>
//         );
//       })}
//     </List>
//   );
// };

// const AdoptedMenu = ({
//   node,
//   deepCopy,
//   setDataset,
//   setDeepCopy,
//   handleClose,
//   setPedigreeState
// }) => {
//   const { theme } = React.useContext(ThemeContext);
//   const { data } = node;

//   const adopted = data?.adopted_in || data?.adopted_out;

//   const onChange = (value) => {
//     const { data } = node;

//     const idx = deepCopy.findIndex((person) => data.pid === person.pid);

//     deepCopy[idx] = {
//       ...deepCopy[idx],
//       adopted: value
//     };

//     setPedigreeState(CONSTANTS.pedigreeState.modified);
//     setDeepCopy((deepCopy) => {
//       return JSON.parse(JSON.stringify(deepCopy));
//     });
//     setDataset(deepCopy);
//     handleClose();
//   };

//   return (
//     <List>
//       {CONSTANTS.adoptedMap.map((option, idx) => {
//         return (
//           <ListItemButton
//             key={idx}
//             style={{
//               backgroundColor: option.value === adopted && theme.buttonStyle
//             }}
//             onClick={() => onChange(option.value)}
//           >
//             {option.label}
//           </ListItemButton>
//         );
//       })}
//     </List>
//   );
// };

// const FertilityMenu = ({
//   node,
//   deepCopy,
//   setDataset,
//   setDeepCopy,
//   handleClose,
//   setPedigreeState
// }) => {
//   const { theme } = React.useContext(ThemeContext);
//   const { data } = node;

//   const totalSpouses = useMemo(() => {
//     if (data && data?.spouse) {
//       return getSpousesWithChildren(data, deepCopy);
//     }
//   }, [data, deepCopy]);

//   const [openModal, setOpenModal] = React.useState(false);

//   const setFertility = (value, selectedSpouse, data) => {
//     let relation_fertility = data.relation_fertility ?? [];

//     if (value) {
//       const rel = relation_fertility.find((rl) => rl.spouse === selectedSpouse);

//       if (rel) {
//         rel.fertility = value;
//       } else {
//         relation_fertility.push({ spouse: selectedSpouse, fertility: value });
//       }
//     } else
//       relation_fertility = relation_fertility.filter(
//         (rl) => rl.spouse !== selectedSpouse
//       );

//     return {
//       ...data,
//       fertility: value,
//       relation_fertility
//     };
//   };

//   const updateData = (data, value, selectedSpouse) => {
//     const idx = deepCopy.findIndex((person) => data.pid === person.pid);
//     const spouse_id = deepCopy.findIndex(
//       (person) => person.pid === selectedSpouse
//     );

//     deepCopy[idx] = setFertility(value, selectedSpouse, deepCopy[idx]);
//     deepCopy[spouse_id] = setFertility(value, data.pid, deepCopy[spouse_id]);

//     setPedigreeState(CONSTANTS.pedigreeState.modified);
//     setDeepCopy((deepCopy) => {
//       return JSON.parse(JSON.stringify(deepCopy));
//     });
//     setDataset(deepCopy);
//     handleClose();
//   };

//   const node_ref = React.useRef();

//   const onChange = (value) => {
//     const { data } = node;
//     node_ref.current = { data, value };

//     if (totalSpouses) {
//       if (totalSpouses.length > 1) {
//         setOpenModal(true);
//       } else {
//         updateData(data, value, totalSpouses[0].spouse.pid);
//       }
//     } else {
//       updateData(data, value);
//     }
//   };

//   const handleSelectSpouse = (spouse) => {
//     updateData(node_ref.current.data, node_ref.current.value, spouse);
//     setOpenModal(false);
//   };

//   return (
//     <List>
//       <CustomDialog
//         title={'Select Spouse'}
//         open={openModal}
//         handleClose={() => setOpenModal(false)}
//         minHeight="auto"
//       >
//         <SelectSpouseModal
//           spousesWithChildren={totalSpouses}
//           handleSelect={handleSelectSpouse}
//         />
//       </CustomDialog>
//       {CONSTANTS.fertilityMap.map((option, idx) => {
//         return (
//           <ListItemButton
//             key={idx}
//             style={{
//               backgroundColor:
//                 option.value === data?.fertility && theme.buttonStyle
//             }}
//             onClick={() => onChange(option.value)}
//           >
//             {option.label}
//           </ListItemButton>
//         );
//       })}
//     </List>
//   );
// };

// const Comment = ({
//   node,
//   deepCopy,
//   setDataset,
//   setDeepCopy,
//   handleClose,
//   setPedigreeState
// }) => {
//   const { theme } = React.useContext(ThemeContext);

//   const style = {
//     bgcolor: theme.appearance.color,
//     position: 'absolute',
//     top: '40%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     borderRadius: 2,
//     p: 2
//   };

//   return (
//     <>
//       <Modal open={node} onClose={handleClose}>
//         <Box sx={style}>
//           <TextModal
//             menu={true}
//             node={node}
//             deepCopy={deepCopy}
//             setDataset={setDataset}
//             setDeepCopy={setDeepCopy}
//             handleClose={handleClose}
//             setPedigreeState={setPedigreeState}
//           />
//         </Box>
//       </Modal>
//     </>
//   );
// };

// const EditText = ({
//   node,
//   deepCopy,
//   setDataset,
//   setDeepCopy,
//   handleClose,
//   setPedigreeState
// }) => {
//   const { theme } = React.useContext(ThemeContext);

//   const style = {
//     bgcolor: theme.appearance.color,
//     position: 'absolute',
//     top: '40%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     borderRadius: 2,
//     p: 2
//   };

//   return (
//     <>
//       <Modal open={node} onClose={handleClose}>
//         <Box sx={style}>
//           <TextModal
//             editText={true}
//             node={node}
//             deepCopy={deepCopy}
//             setDataset={setDataset}
//             setDeepCopy={setDeepCopy}
//             handleClose={handleClose}
//             setPedigreeState={setPedigreeState}
//           />
//         </Box>
//       </Modal>
//     </>
//   );
// };
