import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import { useAutoTranslation } from 'src/hooks/useTranslate';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  p: 4,
  borderRadius: 5
};

function PedigreeConfirmModel({ open, handleClose, onContinue }) {
  const t = useAutoTranslation();

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            {t(`Unsaved Changes`)}
          </Typography>
          <Typography sx={{ m: 4 }}>
            {t(
              `You have unsaved changes in you pedigree. Do you wish to continue`
            )}
          </Typography>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              gap: 10,
              marginTop: 10,
              flexDirection: 'row-reverse'
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => onContinue(e, true)}
            >
              {t(`Save & Exit`)}
            </Button>
            <Button variant="contained" onClick={onContinue}>
              {t(`Discard & Exit`)}
            </Button>
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              {t(`cancel`)}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default PedigreeConfirmModel;
