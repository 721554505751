const basePath = '/questionnaire';

export const GENDER = Object.freeze({
  male: 'male',
  female: 'female',
  unknown: 'unknown'
});

export const GENDER_IDENTITY = Object.freeze({
  male: 'Male',
  female: 'Female',
  indeterminate: 'Indeterminate',
  male_AFAB: 'Male - AFAB',
  maleUAAB: 'Male - UAAB',
  female_AMAB: 'Female - AMAB',
  female_UAAB: 'Female - UAAB',
  nonbinary_genderr_diverse_AFAB: 'Non-binary/gender diverse - AFAB',
  nonbinary_gender_diverse_AMAB: 'Non-binary/gender diverse - AMAB',
  nonbinary_gender_diverse_UAAB: 'Non-binary/gender diverse - UAAB',
  unknown: 'Unknown/Not Stated'
});

export const FERTILITY = Object.freeze({
  infertile: 'infertile',
  fertile: 'fertile'
});

export const defaultPersonalDetailsValues = {
  firstName: {
    label: 'First Name',
    required: true,
    enabled: true,
    mandatory: true
  },
  maidenName: {
    label: 'Middle Name',
    required: false,
    enabled: true
  },
  lastName: {
    label: 'Last Name',
    required: true,
    enabled: true,
    mandatory: true
  },
  // gender: {
  //   label: 'Sex Assigned at Birth',
  //   required: true,
  //   enabled: true,
  //   mandatory: true
  // },
  genderIdentity: {
    label: 'Gender Identity (if different to Sex Assigned at Birth)',
    required: true,
    enabled: true,
    mandatory: true
  },
  selectDiagnoses: {
    label: 'Select Existing Diagnoses',
    required: false,
    enabled: true
  },
  comment: {
    label: 'Comments',
    required: false,
    enabled: true
  },
  pronouns: {
    label: 'Pronouns',
    required: false,
    enabled: true
  },
  dob: {
    label: 'Date of Birth',
    required: false,
    enabled: true
  },
  mobile: {
    label: 'Mobile',
    required: false,
    enabled: true
  },
  address_line1: {
    label: 'Address Line 1',
    required: false,
    enabled: true
  },
  address_line2: {
    label: 'Address Line 2',
    required: false,
    enabled: true
  },
  address_city: {
    label: 'City',
    required: false,
    enabled: true
  },
  address_post: {
    label: 'Post code',
    required: false,
    enabled: true
  },
  gpName: {
    label: 'GP Name',
    required: false,
    enabled: true
  },
  gpAddress_line1: {
    label: 'GP Address Line 1',
    required: false,
    enabled: true
  },
  gpAddress_line2: {
    label: 'GP Address Line 2',
    required: false,
    enabled: true
  },
  gpAddress_city: {
    label: 'City',
    required: false,
    enabled: true
  },
  gpAddress_post: {
    label: 'Post code',
    required: false,
    enabled: true
  },
  healthcareId: {
    label: 'HealthcareID',
    required: false,
    enabled: true
  },
  pregnancy: {
    label: 'Have you ever been pregnant?',
    required: false,
    enabled: true
  },
  discloseIdentity: {
    label:
      'I am happy for you to disclose where you are calling from should someone other than myself answer the phone, and I consent to a message being left.',
    required: false,
    enabled: true
  }
};

export const defaultFamilyDetailsValues = {
  firstName: {
    label: 'First Name',
    required: true,
    enabled: true,
    mandatory: true
  },
  maidenName: {
    label: 'Middle Name',
    required: false,
    enabled: true
  },
  lastName: {
    label: 'Last Name',
    required: false,
    enabled: true
  },
  dob: {
    label: 'Date of Birth',
    required: false,
    enabled: true
  },
  dod: {
    label: 'Date of Death',
    required: false,
    enabled: true
  },
  gender: {
    label: 'Sex Assigned at Birth',
    required: true,
    enabled: true,
    mandatory: true
  },
  genderIdentity: {
    label: 'Gender Identity (if different to Sex Assigned at Birth)',
    required: false,
    enabled: true
  },
  yob: {
    label: 'Year of Birth',
    required: false,
    enabled: true
  },
  yod: {
    label: 'Year of Death',
    required: false,
    enabled: true
  },
  selectRelationship: {
    label: 'Select Relationship',
    required: true,
    enabled: true,
    mandatory: true
  },
  relationSide: {
    label: 'Which side of family?',
    required: false,
    enabled: true
  },
  relationDescribe: {
    label: 'Describe relation',
    required: false,
    enabled: true
  },
  haveTwin: {
    label: 'Does this family member have a twin?',
    required: false,
    enabled: true
  },
  twinMember: {
    label: 'Select twin member',
    required: false,
    enabled: true
  },
  twinType: {
    label: 'Twin type',
    required: false,
    enabled: true
  },
  selectParent: {
    label: 'Select Parent',
    required: true,
    enabled: true,
    mandatory: true
  },
  otherParentName: {
    label: "Other parent's name",
    required: false,
    enabled: true
  },
  age: {
    label: 'Age',
    required: false,
    enabled: true
  },
  lastKnownAddress: {
    label: 'Last known address',
    required: false,
    enabled: true
  },
  selectDiagnoses: {
    label: 'Select Diagnoses',
    required: false,
    enabled: true
  },
  comment: {
    label: 'Comments',
    required: false,
    enabled: true
  }
};

export const defaultRiskSurveyValues = {
  isJewish: {
    label:
      'Some genetic disorders are slightly more common in Jewish families. Are you Jewish or do you have Jewish Ancestry?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isWomanCancer: {
    label: 'Are you a woman who has a history of breast or ovarian cancer?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  majorIllness: {
    label:
      'Have you previously been diagnosed with or suspected of having any medical conditions?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  mainGeneticsQuestions: {
    label:
      'What are your main questions that you would like to discuss with the genetics service?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  riskCancer: {
    label:
      'What do you think your risk of developing this cancer is compared with someone in the general population?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  chancesCancer: {
    label:
      'What do you think your chances are of developing this cancer in your lifetime?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  height: {
    label: 'Height (cm):',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  height_inFoot: {
    label: 'Height (Feet)',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  height_inInches: {
    label: '(Inches)',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  weight: {
    label: 'Weight (kg):',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  weight_inPounds: {
    label: 'Pounds (lbs):',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isDrinkAlcohol: {
    label: 'Do you drink alcohol?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  averageAlcoholConsupmtions: {
    label: 'On average how many drinks do you have and how often?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isSmokeCigarettes: {
    label: 'Do you smoke cigarettes?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  everSmokeCigarettes: {
    label: 'If no, have you ever smoked cigarettes?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  ageFirstPeriod: {
    label: '"How old were you (roughly) when you had your first period?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isPeriodsStop: {
    label: 'Have your periods stopped?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  agePeriodsStop: {
    label: 'If yes, how old were you (roughly) when they stopped?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isTakenOCP: {
    label: 'Have you taken the Oral Contraceptive Pill (OCP)?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  numberYearsTakenOCP: {
    label: 'If yes, for how many years in total?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  lastYearTakenOCP: {
    label: 'What year did you last take OCP?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isTakenHRT: {
    label: 'Have you taken hormonal replacement therapy (HRT)?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  numberYearsTakenHRT: {
    label: 'If yes, for how many years in total?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  lastYearTakenHRT: {
    label: 'What year did you last take HRT?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  typeOfHRTTaken: {
    label: 'If yes, type of HRT (if known):',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isMammogram: {
    label: 'Have you ever had a mammogram?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  BIRADScategory: {
    label: 'If yes, do you know your BI-RADS (breast density) category?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isDiagnosedEndometriosis: {
    label: 'Have you been diagnosed with endometriosis?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isTubesTied: {
    label: 'Have you had your tubes tied (tubal ligation)?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isOvariesOrFallopianRemoved: {
    label: 'Have you had your ovaries and/or fallopian tubes removed?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  typeOfOvariesOrFallopianRemoved: {
    label: 'If yes (please select):',
    required: false,
    enabled: true,
    isLabelDisabled: true
  },
  isMastectomy: {
    label: 'Have you had both breasts removed (Mastectomy)?',
    required: false,
    enabled: true,
    isLabelDisabled: true
  }
};

export const defaultPregnancyDetailsValues = {
  assitReproductiveTherapies: 'Assisted reproductive therapies, including IVF?',
  twins_triplets: 'Twins / triplets?',
  outcome: 'Outcome of Pregnancy',
  deliveryMode: 'Mode of delivery',
  doOutcome: 'Date of Outcome',
  completedWeeks: 'Completed weeks of pregnancy',
  complications:
    'Complications during pregnancy or delivery (select any that apply)',
  comments: 'Comments'
};

// make sure to update backend/util - if this is updated
export const relationshipMap = {
  son: 'Son',
  daughter: 'Daughter',
  fullSister: 'Full Sister',
  halfSister: 'Half Sister',
  fullBrother: 'Full Brother',
  halfBrother: 'Half Brother',
  spouse: 'Spouse',
  mother: 'Mother',
  father: 'Father',
  mothersMother: "Mother's Mother",
  mothersFather: "Mother's Father",
  fathersMother: "Father's Mother",
  fathersFather: "Father's Father",
  mothersBrother: "Mother's Brother",
  mothersSister: "Mother's Sister",
  fathersBrother: "Father's Brother",
  fathersSister: "Father's Sister",
  otherAffected: 'Other relative with disease/disorder'
};
export const spouseRelations = Object.freeze({
  MARRIED: 'married',
  SEPARATED: 'separated',
  CASUAL: 'casual',
  DIVORCED: 'divorced'
});

export const relationshipGenderMap = {
  son: GENDER.male,
  daughter: GENDER.female,
  fullSister: GENDER.female,
  halfSister: GENDER.female,
  fullBrother: GENDER.male,
  halfBrother: GENDER.male,
  mother: GENDER.female,
  father: GENDER.male,
  mothersMother: GENDER.female,
  mothersFather: GENDER.male,
  fathersMother: GENDER.female,
  fathersFather: GENDER.male,
  mothersBrother: GENDER.male,
  mothersSister: GENDER.female,
  fathersBrother: GENDER.male,
  fathersSister: GENDER.female
};

export const diagnosesMap = {
  'breast-cancer': 'Breast Cancer',
  'pancreatic-cancer': 'Pancreatic Cancer',
  'endometrial-cancer': 'Endometrial (Uterus) cancer',
  'colon-cancer': 'Colon Cancer',
  'ovarian-cancer': 'Ovarian Cancer',
  'prostate-cancer': 'Prostate Cancer',
  polyps: 'Polyps',
  'heart-problems': 'Heart problems',
  'eye-problems': 'Eye problems',
  'kidney-problems': 'Kidney problems',
  'neurological-conditions': 'Neurological',
  other: 'Other',
  'blood-disorders': 'Blood Disorders',
  stillbirth: 'Stillbirth',
  'recurrent-misscarriage': 'Recurrent Miscarriage',
  'congenital-abnormalities': 'Congenital Abnormalities',
  hemophilia_a: 'Hemophilia A',
  hemophilia_b: 'Hemophilia B',
  fibrinogen_disorder: 'Fibrinogen Disorder',
  von_willebrand_disease: 'Von Willebrand Disease',
  glanzmann_thrombasthenia: 'Glanzmann Thrombasthenia',
  bernard_soulier_syndrome: 'Bernard Soulier Syndrome',
  hereditary_hemorrhagic_telangiectasia:
    'Hereditary Hemorrhagic Telangiectasia (HHT)',
  platelet_function_disorder: 'Platelet Function Disorder',
  neutropenia: 'Neutropenia',
  protein_c_deficiency: 'Protein C Deficiency',
  protein_s_deficiency: 'Protein S Deficiency',
  antithrombin_iii_deficiency: 'Antithrombin III Deficiency',
  factor_v_leiden: 'Factor V Leiden',
  prothrombin_gene_variant: 'Prothrombin Gene Variant',
  'low_platelet_count_(thrombocytopenia)':
    'Low Platelet Count (Thrombocytopenia)',
  'blood_clot_(thrombosis)': 'Blood Clot (Thrombosis)'
};

export const pregnancyOutcomeMap = {
  delivered: 'Delivered',
  miscarriage: 'Miscarriage',
  stillbirth: 'Stillbirth',
  termination: 'Termination'
};

export const pregnancyDeliveryModeMap = {
  vaginal: 'Vaginal',
  cesarean: 'Cesarean',
  cacuum: 'Vacuum',
  forceps: 'Forceps'
};

export const pregnancyComplicationsMap = {
  'pre-eclampsia': 'Pre-eclampsia',
  'gestational-hypertension': 'Gestational hypertension',
  'gestational-diabetes': 'Gestational diabetes',
  'post-partum-haemorrhage': 'Post-partum haemorrhage',
  'iron-deficiency-anemia': 'Iron-deficiency anemia',
  polyhydramnios: 'Polyhydramnios',
  other: 'Other'
};

export const alcoholFrequencyMap = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
};

export const tubesRemovedOptions = [
  { value: 'ovaries', label: 'Ovaries' },
  { value: 'fallopian', label: 'Fallopian tubes' },
  { value: 'both', label: 'Both' }
];

export const alcoholFrequencyOptions = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' }
];

export const alcoholTypeLabelMap = {
  smallWine: 'Glass of Wine (175 ml)',
  beerPint: 'Pint of Beer / Lager / Cider (568 ml)',
  beerBottle: 'Bottle of Beer (Standard 330 ml)',
  alcoPop: 'Alcoholic Pop Drink (275 ml)',
  spirits:
    'Shot of spirits (25 ml) includes gin, rum, vodka, whisky, tequila, sambuca'
};

export const alcoholTypeUnitUKMap = {
  smallWine: 1.93,
  beerPint: 2.3,
  beerBottle: 1.65,
  alcoPop: 1.1,
  spirits: 1
};

export const alcoholTypeGramsUKMap = {
  smallWine: 15.4,
  beerPint: 18.4,
  beerBottle: 13.2,
  alcoPop: 8.8,
  spirits: 8.0
};

export const alcoholNumberDaysMap = {
  daily: 1,
  weekly: 7,
  monthly: 30
};

export const alcoholTypelMap = {
  smallWine: 'smallWine',
  beerPint: 'beerPint',
  beerBottle: 'beerBottle',
  alcoPop: 'alcoPop',
  spirits: 'spirits'
};

export const presetAlcoholConsumptionDetails = [
  {
    type: alcoholTypelMap.smallWine,
    frequency: '',
    amount: 0
  },
  {
    type: alcoholTypelMap.beerPint,
    frequency: '',
    amount: 0
  },
  {
    type: alcoholTypelMap.beerBottle,
    frequency: '',
    amount: 0
  },
  {
    type: alcoholTypelMap.alcoPop,
    frequency: '',
    amount: 0
  },
  {
    type: alcoholTypelMap.spirits,
    frequency: '',
    amount: 0
  }
];
export const linkParametersMap = {
  cardiac: 'Cardiac',
  cancer: 'Cancer',
  blood: 'Blood'
};

export const relationsOptions = [
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Full Sister', value: 'fullSister' },
  { label: 'Half Sister', value: 'halfSister' },
  { label: 'Full Brother', value: 'fullBrother' },
  { label: 'Half Brother', value: 'halfBrother' },
  { label: 'Spouse', value: 'spouse' },
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: "Mother's Mother", value: 'mothersMother' },
  { label: "Mother's Father", value: 'mothersFather' },
  { label: "Father's Mother", value: 'fathersMother' },
  { label: "Father's Father", value: 'fathersFather' },
  { label: "Mother's Brother", value: 'mothersBrother' },
  { label: "Mother's Sister", value: 'mothersSister' },
  { label: "Father's Brother", value: 'fathersBrother' },
  { label: "Father's Sister", value: 'fathersSister' },
  { label: 'Other relative with disease/disorder', value: 'otherAffected' }
];

export const singeAddRelations = [
  'spouse',
  'mother',
  'father',
  'mothersMother',
  'mothersFather',
  'fathersMother',
  'fathersFather'
];

export const womenGeneticData = [
  'isWomanCancer',
  'ageFirstPeriod',
  'isPeriodsStop',
  'agePeriodsStop',
  'isTakenOCP',
  'numberYearsTakenOCP',
  'lastYearTakenOCP',
  'isWomanCancer',
  'isTakenHRT',
  'numberYearsTakenHRT',
  'lastYearTakenHRT',
  'typeOfHRTTaken',
  'isMammogram',
  'BIRADScategory',
  'isDiagnosedEndometriosis',
  'isTubesTied',
  'isOvariesOrFallopianRemoved',
  'typeOfOvariesOrFallopianRemoved',
  'isMastectomy'
];

const RELATIONS = {
  children: 'children',
  siblings: 'siblings',
  halfSiblings: 'halfSiblings',
  mothersSibling: 'mothersSibling',
  fathersSibling: 'fathersSibling'
};

// map of all relations used to populate the twin options
export const relationMap = {
  self: RELATIONS.siblings,
  mother: RELATIONS.mothersSibling,
  father: RELATIONS.fathersSibling,
  son: RELATIONS.children,
  daughter: RELATIONS.children,
  fullSister: RELATIONS.siblings,
  fullBrother: RELATIONS.siblings,
  halfSister: RELATIONS.halfSiblings,
  halfBrother: RELATIONS.halfSiblings,
  mothersBrother: RELATIONS.mothersSibling,
  mothersSister: RELATIONS.mothersSibling,
  fathersBrother: RELATIONS.fathersSibling,
  fathersSister: RELATIONS.fathersSibling
};

export const allRelations = {
  mother: 'mother',
  father: 'father',
  mothersMother: 'mothersMother',
  mothersFather: 'mothersFather',
  fathersMother: 'fathersMother',
  fathersFather: 'fathersFather',
  fullSister: 'fullSister',
  fullBrother: 'fullBrother',
  halfSister: 'halfSister',
  halfBrother: 'halfBrother',
  daughter: 'daughter',
  son: 'son',
  mothersSister: 'mothersSister',
  mothersBrother: 'mothersBrother',
  fathersSister: 'fathersSister',
  fathersBrother: 'fathersBrother',
  spouse: 'spouse',
  otherAffected: 'otherAffected'
};

export const CONSTANTS = Object.freeze({
  formPath: basePath,
  generateLinkPath: `${basePath}/generate-link`,
  title: 'Family History Enquiry Form',

  disclaimerLabel: 'Instructions',
  disclaimerPath: `${basePath}/instructions`,

  personalDetailsLabel: 'Personal Details',
  personalDetailsPath: `${basePath}/personal-details`,

  familyDetailsLabel: 'Family Details',
  familyDetailsPath: `${basePath}/family-details`,
  additionalFamilyDetailsPath: `${basePath}/additional-family-details`,

  pregnancyDetailsLabel: 'Pregnancy Details',
  pregnancyDetailsPath: `${basePath}/pregnancy-details`,

  // confirmDetailsLabel: 'Confirm Family Details',
  // confirmDetailsPath: `${basePath}/confirm-details`,

  geneticDetailsLabel: 'Risk Survey',
  geneticDetailsPath: `${basePath}/genetic-details`,

  reviewLabel: 'Confirm all details',
  reviewPath: `${basePath}/review`,

  customisationPath: `${basePath}/additional-questions`,

  saveNotif: 'Details saved',
  errorNotif: 'Error saving values, please try again',

  saveAndContinueLaterTitle: 'Save and Continue Later',
  saveAndContinueLaterButton: 'Save & Continue Later',
  distanceTopSaveAndReturnLaterDialog: 50,
  saveAndContinueLaterMessage:
    'Your data has been saved so you may safely close the browser.  To return to the FHQ later, simply click the link from your email again',

  yesNoOptions: [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' }
  ],
  chancesCancerOptions: [
    { label: '0%', value: '0' },
    { label: '25%', value: '25' },
    { label: '50%', value: '50' },
    { label: '75%', value: '75' },
    { label: '100%', value: '100' }
  ],
  riskCancerOptions: [
    { label: 'Much Less', value: 'muchLess' },
    { label: 'Slightly Less', value: 'slightlyLess' },
    { label: 'Same As', value: 'sameAs' },
    { label: 'Slightly Higher', value: 'slightlyHigher' },
    { label: 'Much Higher', value: 'muchHigher' }
  ],
  otherRelationshipSideOptions: [
    { value: 'Maternal', label: 'Maternal' },
    { value: 'Paternal', label: 'Paternal' }
  ],

  genderOptions: [
    { value: 'female', label: 'Female' },
    { value: 'male', label: 'Male' },
    { value: 'unknown', label: 'Other' }
  ],

  pregnancyDeliveryModesOptions: [
    { label: 'Vaginal', value: 'vaginal' },
    { label: 'Cesarean', value: 'cesarean' },
    { label: 'Vacuum', value: 'vacuum' },
    { label: 'Forceps', value: 'forceps' }
  ],

  twinTypeOptions: [
    { label: 'Identical', value: 'identical' },
    { label: 'Non-Identical', value: 'non-identical' }
  ],

  halfRelation: ['halfSister', 'halfBrother'],
  twinRelation: ['fullSister', 'fullBrother'],
  nonTwinRelation: [
    'spouse',
    'mother',
    'father',
    'mothersMother',
    'mothersFather',
    'fathersMother',
    'fathersFather',
    'otherAffected'
  ],

  genderIdentityOptions: [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Indeterminate', value: 'Indeterminate' },
    { label: 'Male - Assigned Female at Birth', value: 'Male - AFAB' },
    {
      label: 'Male -  Unspecified/Unknown Assigned at Birth',
      value: 'Male - UAAB'
    },
    { label: 'Female - Assigned Male at Birth', value: 'Female - AMAB' },
    {
      label: 'Female - Unspecified/Unknown Assigned at Birth',
      value: 'Female - UAAB'
    },
    {
      label: 'Non-binary/gender diverse -  Assigned Female at Birth',
      value: 'Non-binary/gender diverse - AFAB'
    },
    {
      label: 'Non-binary/gender diverse - Assigned Male at Birth',
      value: 'Non-binary/gender diverse - AMAB'
    },
    {
      label:
        'Non-binary/gender diverse - Unspecified/Unknown Assigned at Birth',
      value: 'Non-binary/gender diverse - UAAB'
    },
    { label: 'Unknown/Not Stated ', value: 'Unknown/Not Stated ' }
  ],

  spouseRelationshipOptions: [
    { label: 'Married', value: spouseRelations.MARRIED },
    { label: 'Separated', value: spouseRelations.SEPARATED },
    { label: 'Divorced', value: spouseRelations.DIVORCED },
    { label: 'Casual', value: spouseRelations.CASUAL }
  ],

  genderMappings: {
    Male: 'male',
    Female: 'female',
    Indeterminate: 'unknown',
    'Male - AFAB': 'female',
    'Male - UAAB': 'unknown',
    'Female - AMAB': 'male',
    'Female - UAAB': 'unknown',
    'Non-binary/gender diverse - AFAB': 'female',
    'Non-binary/gender diverse - AMAB': 'male',
    'Non-binary/gender diverse - UAAB': 'unknown',
    'Unknown/Not Stated Unknown': 'unknown'
  },

  generalDiagnoses: [
    'heart-problems',
    'eye-problems',
    'kidney-problems',
    'neurological-conditions',
    'other'
  ],
  diagnoses: [
    'breast-cancer',
    'pancreatic-cancer',
    'endometrial-cancer',
    'colon-cancer',
    'ovarian-cancer',
    'prostate-cancer',
    'polyps'
  ],
  pregnanciesDiagnoses: [
    'stillbirth',
    'recurrent-misscarriage',
    'congenital-abnormalities'
  ],
  bloodDiagnoses: [
    'blood-disorders',
    'hemophilia_a',
    'hemophilia_b',
    'fibrinogen_disorder',
    'von_willebrand_disease',
    'glanzmann_thrombasthenia',
    'bernard_soulier_syndrome',
    'hereditary_hemorrhagic_telangiectasia',
    'platelet_function_disorder',
    'neutropenia',
    'protein_c_deficiency',
    'protein_s_deficiency',
    'antithrombin_iii_deficiency',
    'factor_v_leiden',
    'prothrombin_gene_variant',
    'low_platelet_count_(thrombocytopenia)',
    'blood_clot_(thrombosis)'
  ],
  pregnancyOutcomes: [
    { label: 'Delivered', value: 'delivered' },
    { label: 'Miscarriage', value: 'miscarriage' },
    { label: 'Stillbirth', value: 'stillbirth' },
    { label: 'Termination', value: 'termination' }
  ],

  pregnancyComplications: [
    'pre-eclampsia',
    'gestational-hypertension',
    'gestational-diabetes',
    'post-partum-haemorrhage',
    'iron-deficiency-anemia',
    'polyhydramnios',
    'other'
  ],

  alcoholLabelType: [
    'smallWine',
    'beerPint',
    'beerBottle',
    'alcoPop',
    'spirits'
  ],

  alcoholFrequency: ['daily', 'weekly', 'monthly'],

  linkParameters: ['cancer', 'cardiac', 'blood'],

  instructions: [
    'Please provide details of family members that have been affected by disease(s) or disorder(s) and those that have not. This is important in assessing your risk of inherited diseases and disorders.',
    'Try to complete all sections, if some are not relevant then please put N/A for "not applicable".',
    'Some  first  names  can  be  used  for  males  or  females,  or  are  unusual.  Please  could  you  put "Male" or "Female" in the appropriate column.',
    'The more details you provide, the more accurate your assessment can be.'
  ],
  consentNotProvided:
    'The consent has not been provided by the family member to allow full value',

  defaultFamilyDetails: {
    // relationship: "",
    // gender: "",
    // firstName: "",
    // maidenName: "",
    // lastName: "",
    // halfParent: "",
    // twinMember: "",
    // twinType: "",
    // otherParent: "",
    // isAlive: "",
    // isTwin: "",
    // dob: null,
    // dod: null,
    diagnoses: [],
    // comments: "",
    // age: "",
    diagnosesAge: {},
    consent: {}
  },

  defaultPregnancyDetails: {
    complications: []
  },

  initialContent: {
    validated: false,
    token: '',
    personalDetails: {
      // _id: null,
      // gender: '',
      // proBandId: '',
      // firstName: '',
      // lastName: '',
      // maidenName: '',
      // dob: null,
      // dod: null,
      // mobile: '',
      // address_line1: '',
      // address_line2: '',
      // address_city: '',
      // address_post: '',
      // address_state: '',
      // gpName: '',
      // gpAddress_line1: '',
      // gpAddress_line2: '',
      // gpAddress_city: '',
      // gpAddress_post: '',
      // discloseIdentity: '',
      // familyMembers: [],
      // // receiveLetter: '',
      // submitted: false
    },
    familyAdditionalDetails: {},
    relations: relationsOptions,
    family: [],
    geneticDetails: {
      // _id: null,
      // isJewish: "",
      // isWomanCancer: "",
      // majorIllness: "",
      // riskCancer: "",
      // chancesCancer: "",
      // mainGeneticsQuestions: "",
    },
    bloodSpecialityDetails: {
      epistaxis: '0',
      cutaneous: '0',
      minorBleeding: '0',
      oralCavity: '0',
      giBleeding: '0',
      hematuria: '0',
      toothExtraction: '0',
      surgery: '0',
      menorrhagia: '0',
      hemorrhage: '0',
      hematomas: '0',
      hemarthrosis: '0',
      cnsBleeding: '0',
      otherBleeding: '0'
    },
    customTheme: {
      appearance: {
        value: 'light',
        color: '#FFFF',
        shade: '#DDDDDD',
        text: '#000'
      },
      navbar: '#FFFFFF',
      navbarText: '#000',
      accent: '#2ba9bf',
      header: '#2ba9bf',
      buttonStyle: '#f50057',
      buttonText: '#FFFFFF'
    },
    customisation: {
      isEnabled: false,
      instructions: [],
      questions: [],
      questionsTitle: ''
    },
    formValidationRules: {
      saveSurnameInitials: true,
      saveDateAsYears: true
    },
    isEnablePregnancy: false,
    isEnableRiskSurvey: false
  },

  methods: {
    fetch: 'fetch',
    save: 'save',
    delete: 'delete',
    submit: 'submit'
  },
  tokenKey: 'fhq_token',
  clientGroupKey: 'client_group_id',
  linkParametersKey: 'link_parameters',

  // used to clean the form data
  removals: [
    'createdAt',
    'updatedAt',
    'status',
    'proBandId',
    'familyId',
    'dateGenerated',
    'currentId',
    'client_id',
    '__v'
  ],

  defaultFamilyAccordions: [
    {
      id: 'panel-mother',
      relationship: allRelations['mother'],
      title: 'Mother',
      gender: GENDER['female']
    },
    {
      id: 'panel-father',
      relationship: allRelations['father'],
      title: 'Father',
      gender: GENDER['male']
    },
    {
      id: 'panel-mothersMother',
      relationship: allRelations['mothersMother'],
      title: 'Maternal Grandmother (Mother’s Mother)',
      gender: GENDER['female']
    },
    {
      id: 'panel-mothersFather',
      relationship: allRelations['mothersFather'],
      title: 'Maternal Grandfather (Mother’s Father)',
      gender: GENDER['male']
    },
    {
      id: 'panel-fathersMother',
      relationship: allRelations['fathersMother'],
      title: 'Paternal Grandmother (Father’s Mother)',
      gender: GENDER['female']
    },
    {
      id: 'panel-fathersFather',
      relationship: allRelations['fathersFather'],
      title: 'Paternal Grandfather (Father’s Father)',
      gender: GENDER['male']
    }
  ],

  booleanCheckFamilyKeys: [
    'isBrotherSister',
    'isSpouse',
    'isChildren',
    'isMumBrotherSister',
    'isDadBrotherSister',
    'isOtherRelative'
  ],

  numberCheckFamilyKeys: [
    'sisterCount',
    'brotherCount',
    'halfSisterCount',
    'halfBrotherCount',
    'daughterCount',
    'sonCount',
    'mumSisterCount',
    'mumBrotherCount',
    'dadSisterCount',
    'dadBrotherCount',
    'otherRelativeCount'
  ]
});

export const getNavItems = (
  isCustomisationEnabled = false,
  customisationTitle,
  isPregnancyEnabled = false,
  isPregnancies = false,
  isEnabledRiskSurvey = false
) => [
  {
    path: CONSTANTS.disclaimerPath,
    label: CONSTANTS.disclaimerLabel,
    icon: 'home'
  },
  {
    path: CONSTANTS.personalDetailsPath,
    label: CONSTANTS.personalDetailsLabel,
    icon: 'person'
  },
  ...(isPregnancyEnabled && isPregnancies
    ? [
        {
          path: CONSTANTS.pregnancyDetailsPath,
          label: CONSTANTS.pregnancyDetailsLabel,
          icon: 'pregnant'
        }
      ]
    : []),
  {
    path: CONSTANTS.familyDetailsPath,
    label: CONSTANTS.familyDetailsLabel,
    icon: 'people'
  },
  // {path: CONSTANTS.confirmDetailsPath, label: CONSTANTS.confirmDetailsLabel, icon: 'numbered-list'},
  ...(isEnabledRiskSurvey
    ? [
        {
          path: CONSTANTS.geneticDetailsPath,
          label: CONSTANTS.geneticDetailsLabel,
          icon: 'wc'
        }
      ]
    : []),
  ...(isCustomisationEnabled
    ? [
        {
          path: CONSTANTS.customisationPath,
          label: customisationTitle,
          icon: 'post-add'
        }
      ]
    : []),
  {
    path: CONSTANTS.reviewPath,
    label: CONSTANTS.reviewLabel,
    icon: 'list-check'
  }
];

export const bloodSpecialityTableHead = [
  'SYMPTOMS (up to the time of diagnosis)',
  '0',
  '1',
  '2',
  '3',
  '4'
];

export const bloodSpecialityTableData = [
  {
    id: 'epistaxis',
    symptoms: 'Epistaxis',
    0: 'No/trivial',
    1: '- > 5/year or - more than 10 minutes',
    2: 'Consultation only*',
    3: 'Packing or cauterization or antifibrinolytic',
    4: 'Blood transfusion or replacement therapy (use of hemostatic blood components and rFVIIa) or desmopressin'
  },
  {
    id: 'cutaneous',
    symptoms: 'Cutaneous',
    0: 'No/trivial',
    1: 'For bruises 5 or more (> 1cm) in exposed areas',
    2: 'Consultation only*',
    3: 'Extensive',
    4: 'Spontaneous hematoma requiring blood transfusion'
  },
  {
    id: 'minorBleeding',
    symptoms: 'Bleeding from minor wounds',
    0: 'No/trivial',
    1: '- > 5/year or - more than 10 minutes',
    2: 'Consultation only*',
    3: 'Surgical hemostasis',
    4: 'Blood transfusion, replacement therapy, or desmopressin'
  },
  {
    id: 'oralCavity',
    symptoms: 'Oral cavity',
    0: 'No/trivial',
    1: 'Present',
    2: 'Consultation only*',
    3: 'Surgical hemostasis or antifibrinolytic',
    4: 'Blood transfusion, replacement therapy or desmopressin'
  },
  {
    id: 'giBleeding',
    symptoms: 'GI bleeding',
    0: 'No/trivial',
    1: 'Present (not associated with ulcer, portal hypertension, hemorrhoids, angiodysplasia)',
    2: 'Consultation only*',
    3: 'Surgical hemostasis, antifibrinolytic',
    4: 'Blood transfusion, replacement therapy or desmopressin'
  },
  {
    id: 'hematuria',
    symptoms: 'Hematuria',
    0: 'No/trivial',
    1: 'Present (macroscopic)',
    2: 'Consultation only*',
    3: 'Surgical hemostasis, iron therapy',
    4: 'Blood transfusion, replacement therapy or desmopressin'
  },
  {
    id: 'toothExtraction',
    symptoms: 'Tooth extraction',
    0: 'No/trivial or none done',
    1: 'Reported in ≤25% of all procedures, no intervention**',
    2: 'Reported in ≥25% of all procedures, no intervention**',
    3: 'Resuturing or packing',
    4: 'Blood transfusion, replacement therapy or desmopressin'
  },
  {
    id: 'surgery',
    symptoms: 'Surgery',
    0: 'No/trivial or none done',
    1: 'Reported in ≤25% of all procedures, no intervention**',
    2: 'Reported in ≥25% of all procedures, no intervention**',
    3: 'Surgical hemostasis or antifibrinolytic',
    4: 'Blood transfusion, replacement therapy or desmopressin'
  },
  {
    id: 'menorrhagia',
    symptoms: 'Menorrhagia',
    0: 'No/trivial',
    1: 'Consultation only* or - Changing pads more frequently than every 2 hours or - Clot and flooding or - PBAC score>100#',
    2: '- Time off work/school > 2/year or - Requiring antifibrinolytics or hormonal or iron therapy',
    3: '- Requiring combined treatment with antifibrinolytics and hormonal therapy or - Present since menarche and > 12 months',
    4: '- Acute menorrhagia requiring hospital admission and emergency treatment or - Requiring blood transfusion, Replacement therapy, Desmopressin, or - Requiring dilatation & curretage or endometrial ablation or hysterectomy)'
  },
  {
    id: 'hemorrhage',
    symptoms: 'Post-partum hemorrhage',
    0: 'No/trivial or no deliveries',
    1: 'Consultation only* or - Use of syntocin or - Lochia > 6 weeks',
    2: '- Iron therapy or - Antifibrinolytics',
    3: '- Requiring blood transfusion, replacement therapy, desmopressin or - Requiring examination under anaesthesia and/or the use of uterin balloon/package to tamponade the uterus',
    4: '- Any procedure requiring critical care or surgical intervention (e.g. hysterectomy, internal iliac artery legation, uterine artery embolization, uterine brace sutures)'
  },
  {
    id: 'hematomas',
    symptoms: 'Muscle hematomas',
    0: 'Never',
    1: 'Post trauma, no therapy',
    2: 'Spontaneous, no therapy',
    3: 'Spontaneous or traumatic, requiring desmopressin or replacement therapy',
    4: 'Spontaneous or traumatic, requiring surgical intervention or blood transfusion'
  },
  {
    id: 'hemarthrosis',
    symptoms: 'Hemarthrosis',
    0: 'Never',
    1: 'Post trauma, no therapy',
    2: 'Spontaneous, no therapy',
    3: 'Spontaneous or traumatic, requiring desmopressin or replacement therapy',
    4: 'Spontaneous or traumatic, requiring surgical intervention or blood transfusion'
  },
  {
    id: 'cnsBleeding',
    symptoms: 'CNS bleeding',
    0: 'Never',
    3: 'Subdural, any intervention',
    4: 'Intracerebral, any intervention'
  },
  {
    id: 'otherBleeding',
    symptoms: 'Other bleedings^',
    0: 'No/trivial',
    1: 'Present',
    2: 'Consultation only*',
    3: 'Surgical hemostasis, antifibrinolytics',
    4: 'Blood transfusion or replacement therapy or desmopressin'
  }
];
