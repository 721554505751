import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import FormLabel from 'src/components/UI/input/CustomFormLabel';
import DateInput from 'src/components/UI/input/DateInput';
import SelectInput from 'src/components/UI/input/SelectInput';
import MultipleSelectInput from 'src/components/UI/input/MultipleSelectInput';
import AutocompleteInput from 'src/components/UI/input/AutocompleteInput';
import InfoTooltip from 'src/components/UI/addons/InfoTooltip';
import SimpleAutocompleteInput from './SimpleAutocompleteInput';
import { ThemeContext } from 'src/store/ContextStore';

export const textStyles = (theme) => {
  return makeStyles({
    '@global': {
      '.MuiOutlinedInput-input': {
        backgroundColor: theme
          ? theme.appearance.value !== 'light' && theme.appearance.shade
          : '#fff',
        color: theme ? theme.appearance.text : '#000'
      }
    },
    disabledInput: {
      '& .MuiInputBase-root.Mui-disabled': {
        color: '#000'
      },
      '& .MuiInputBase-input.Mui-disabled': {
        backgroundColor: theme
          ? theme.appearance.value !== 'light' && theme.appearance.shade
          : '#fff',
        cursor: 'not-allowed'
      },
      '& input': {
        textOverflow: 'ellipsis'
      },
      '& .Mui-error': {
        marginLeft: 0
      }
    },
    green: {
      color: 'green'
    },
    labelContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 5
    }
  })();
};

export default React.forwardRef(function TextInput(props, ref) {
  // props specific to this component only
  const {
    label,
    type,
    // updateParent,
    updateParentOnBlur,
    validationFn,
    infoLabel,
    id,
    ...rest
  } = props;

  const { theme } = React.useContext(ThemeContext);

  const { name, value, onChange, disabled } = props;
  const classes = textStyles(theme);

  // TODO try using debounce
  const [inputValue, setInputValue] = React.useState(value);

  React.useEffect(() => {
    if (updateParentOnBlur) setInputValue(value);
  }, [updateParentOnBlur, value]);

  const handleChange = React.useCallback(
    (e) => {
      if (updateParentOnBlur) {
        if (!validationFn) {
          return setInputValue(e.target.value);
        }
        if (validationFn(e.target.value)) setInputValue(e.target.value);
      }
      return onChange(e);
    },
    [onChange, updateParentOnBlur, validationFn]
  );

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {label && (
        <Grid item className={classes.labelContainer}>
          <FormLabel required={props.required}>{label}</FormLabel>

          {infoLabel && <InfoTooltip label={infoLabel} />}
        </Grid>
      )}
      {props.helpText && (
        <Grid item xs={12}>
          {props.helpText}
        </Grid>
      )}
      <Grid item xs={12}>
        {(!type || type === 'text' || type === 'email') && (
          <TextField
            {...rest}
            // value={updateParent ? value : inputValue || ""}
            // onChange={updateParent ? onChange : e => setInputValue(e.target.value)}
            // onBlur={updateParent ? undefined : onChange}
            value={updateParentOnBlur ? inputValue : value}
            onChange={handleChange}
            onBlur={
              updateParentOnBlur && inputValue !== value ? onChange : undefined
            }
            className={classes.disabledInput}
            variant="outlined"
            fullWidth
            validated={undefined}
            inputRef={ref}
          />
        )}
        {type === 'date' && (
          <DateInput
            {...rest}
            variant="outlined"
            // fullWidth
            showPlaceholder
          />
        )}
        {type === 'select' && (
          <SelectInput
            {...rest}
            variant="outlined"
            value={value || ''}
            className={classes.disabledInput}
            fullWidth
            showPlaceholder
          />
        )}
        {type === 'multiple-select' && (
          <MultipleSelectInput
            name={name}
            value={value}
            onChange={onChange}
            mapper={props.mapper}
            options={props.options}
          />
        )}
        {type === 'textarea' && (
          <TextField
            {...rest}
            variant="outlined"
            helperText={props.captionText || props.helperText}
            name={name}
            value={updateParentOnBlur ? inputValue : value}
            onChange={handleChange}
            onBlur={
              updateParentOnBlur && inputValue !== value ? onChange : undefined
            }
            multiline
            rows={props.rows ?? 4}
            disabled={disabled ?? undefined}
            className={classes.disabledInput}
            inputRef={ref}
            fullWidth
            id={id}
          />
        )}
        {type === 'autocomplete' && (
          <AutocompleteInput {...rest} className={classes.disabledInput} />
        )}
        {type === 'simple-autocomplete' && (
          <SimpleAutocompleteInput
            {...rest}
            className={classes.disabledInput}
          />
        )}
      </Grid>
    </Grid>
  );
});
